import { NgFor, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BslkIconButtonComponent } from '@bslk/components/button/icon/icon-button.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ESurveyQuestionType, SurveyQuestionViewModel } from 'app/api';
import { SurveyFormService } from 'app/shared/services/form/survey-form.service';
import { BslkTagButtonComponent } from '../../../../../../../@bslk/components/cards/tag-button/tag-button.component';

@Component({
    selector: 'company-admin-survey-configuration-question',
    templateUrl: './question.component.html',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        TranslocoModule,
        MatIconModule,
        MatSelectModule,
        BslkIconButtonComponent,
        BslkClassicToggleComponent,
        NgFor,
        NgIf,
        BslkTagButtonComponent,
    ],
})
export class CompanyAdminSurveyConfigurationQuestionComponent {
    @Input() group: FormGroup;
    @Input() index: number;

    ESurveyQuestionType = ESurveyQuestionType;

    constructor(private formService: SurveyFormService) {}

    get surveyFormGroup() {
        return this.formService.getFormGroup;
    }

    get isQuiz() {
        return this.formService.getFormGroup.controls.isQuiz.value;
    }

    get formArray() {
        return this.formService.getFormGroup.controls.questions as FormArray;
    }

    get choicesFormArray() {
        return this.group.controls.choices as FormArray;
    }

    get hasChoices() {
        return (
            this.group.value.type === ESurveyQuestionType.SingleChoice ||
            this.group.value.type === ESurveyQuestionType.MultipleChoice
        );
    }

    get dynamicError() {
        return this.formService.dynamicQuestionsErrors[this.index];
    }

    removeQuestion() {
        this.formArray.removeAt(this.index);
        this.formArray.markAsDirty();
    }

    addChoice() {
        this.choicesFormArray.push(this.formService.createChoice());
    }

    removeChoice(index: number) {
        this.choicesFormArray.removeAt(index);
        this.choicesFormArray.markAsDirty();
    }

    duplicate() {
        const question: SurveyQuestionViewModel = this.group.value;
        question.id = 0;
        const newQuestion = this.formService.createQuestion(question);
        this.formArray.push(newQuestion);
        setTimeout(() => {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        });
    }

    typeChange(event: MatSelectChange) {
        if (
            event.value === ESurveyQuestionType.ShortAnswer ||
            event.value === ESurveyQuestionType.Paragraph ||
            event.value === ESurveyQuestionType.Rating
        ) {
            this.choicesFormArray.clear();
        }
    }
}
