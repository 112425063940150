import { NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'survey-generic-bloc',
    templateUrl: './generic-bloc.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatIconModule,
        MatCheckboxModule,
        NgIf,
    ],
})
export class SurveyGenericBlocComponent {
    @Input() label: string;
    @Input() labelColor: BslkColor;
    @Input() group: FormGroup;
    @Input() control: FormControl;
    @Input() isRequired: boolean;
    @Input() showErrors: boolean;
    @Input() borderColor: BslkColor = 'warn';

    constructor() {}

    get hasError() {
        return this.showErrors && (this.control ?? this.group).invalid;
    }

    get isRequiredNotMet() {
        return this.control
            ? this.control.hasError('required')
            : Object.values(this.group.controls).some((control) =>
                  control.hasError('required')
              );
    }
}
