import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkRoundIconComponent } from '@bslk/components/round-icon/round-icon.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import {
    DonationCampaignViewModel,
    EDonationCampaignState,
    EFileExtension,
    EFileType,
} from 'app/api';
import {
    ECompanyAdminUrl,
    EExternalUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { DonationCampaignCustomizationActions } from 'app/store/donation/donation-campaign-customization/donation-campaign-customization.action';
import { DonationCampaignActions } from 'app/store/donation/donation-campaign/donation-campaign.action';
import { DonationCampaignSelectors } from 'app/store/donation/donation-campaign/donation-campaign.selectors';
import { FilesActions } from 'app/store/files/files.action';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { CreateDonationCampaignDialogService } from '../donation-campaigns/create/create.service';
import { DonationCampaignLegalDocumentsComponent } from './legal-documents/legal-documents.component';
import { DonationCampaignRequestsListComponent } from './requests-list/requests-list.component';
import { DonationCampaignRequestsRepartitionComponent } from './requests-repartition/requests-repartition.component';
import { DonationCampaignShareLinkComponent } from './share-link/share-link.component';
import { DonationCampaignWhitelistedCodesComponent } from './whitelisted-codes/whitelisted-codes.component';

@Component({
    selector: 'donation-campaign',
    templateUrl: './donation-campaign.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BslkRoundIconComponent,
        BslkTagComponent,
        BslkTagButtonComponent,
        DonationCampaignRequestsListComponent,
        DonationCampaignRequestsRepartitionComponent,
        DonationCampaignShareLinkComponent,
        DonationCampaignWhitelistedCodesComponent,
        DonationCampaignLegalDocumentsComponent,
        LetDirective,
        MatIconModule,
        MatTooltipModule,
        NgIf,
        NgSwitch,
        NgSwitchCase,
        NgxSkeletonLoaderModule,
        MatSidenavModule,
        TranslocoModule,
    ],
})
export class DonationCampaignComponent implements OnInit {
    unsubscribeAll: Subject<any> = new Subject<any>();

    EDonationCampaignState = EDonationCampaignState;

    campaign$ = this.store.select(
        DonationCampaignSelectors.selectDonationCampaign
    );
    isLoading$ = this.store.select(DonationCampaignSelectors.selectIsLoading);
    displayWhitelistedCodes$ = this.store.select(
        DonationCampaignSelectors.selectDisplayWhitelistedCodes
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private router: Router,
        private createCampaignDialogService: CreateDonationCampaignDialogService,
        private translocoService: TranslocoService
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const id = params['id'];
                if (id) {
                    this.store.dispatch(
                        DonationCampaignActions.loadDonationCampaignRequestById(
                            {
                                id,
                            }
                        )
                    );
                }
            });
    }

    goBack() {
        this.router.navigateByUrl(
            UrlHelpers.getCompanyAdminUrl(
                ECompanyAdminUrl.CompanyAdminDonationCampaignsPage
            )
        );
    }

    downloadFundraisingAppeal(donationCampaignId: number) {
        this.store.dispatch(
            FilesActions.selectFile({
                file: {
                    name: this.translocoService.translate(
                        'donationcampaign.fundraising-appeal'
                    ),
                    longDescription: this.translocoService.translate(
                        'donationcampaign.fundraising-appeal-description'
                    ),
                    fileExtension: EFileExtension.Pdf,

                    fileType: EFileType.DonationCampaignFundraisingAppeal,
                    hiddenProperties: {
                        donationCampaignId,
                    },
                },
            })
        );
    }

    downloadTaxReceipt(donationCampaignId: number) {
        this.store.dispatch(
            FilesActions.selectFile({
                file: {
                    name: this.translocoService.translate(
                        'donationcampaign.tax-receipt'
                    ),
                    longDescription: this.translocoService.translate(
                        'donationcampaign.tax-receipt-description'
                    ),
                    fileExtension: EFileExtension.Pdf,

                    fileType: EFileType.DonationCampaignTaxReceipt,
                    hiddenProperties: {
                        donationCampaignId,
                    },
                },
            })
        );
    }
    onMenuAction(event: string, campaign: DonationCampaignViewModel) {
        if (event === 'modify') {
            return this.modifyCampaign();
        }
        if (event === 'preview') {
            return this.openPreview(campaign);
        }
    }

    modifyCampaign() {
        this.createCampaignDialogService.open({
            isEdit: true,
            isFromEntityPage: true,
        });
    }

    customizeCampaign(campaign: DonationCampaignViewModel) {
        this.store.dispatch(
            DonationCampaignCustomizationActions.openDialog({
                input: {
                    isDefaultCustomization: false,
                    donationCampaignId: campaign.id,
                },
            })
        );
    }

    openPreview(campaign: DonationCampaignViewModel) {
        window.open(
            UrlHelpers.getExternalUrl(EExternalUrl.DonationGiftPreview, {
                id: campaign.accessToken,
            }),
            '_blank'
        );
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
