import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { CompanyAdminGuard } from 'app/core/auth/guards/roles.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AssoProfileViewComponent } from '../association/profile/view/asso-profile-view.component';
import { AwarenessCampaignComponent } from './awareness/campaign/campaign.component';
import { CompanyFiltersComponent } from './company-filters/company-filters.component';
import { CompanyAdminDashboardComponent } from './dashboard/company-admin-dashboard.component';
import { DonationCampaignComponent } from './donation-campaign/donation-campaign.component';
import { DonationCampaignsComponent } from './donation-campaigns/donation-campaigns.component';
import { CompanyAdminSetupComponent } from './setup/setup.component';
import { CompanyAdminSurveyConfigurationComponent } from './surveys/survey-configuration/survey-configuration.component';
import { CompanyAdminSurveysComponent } from './surveys/surveys.component';

export const companyAdminRoutes: Route[] = [
    { path: 'ca', pathMatch: 'full', redirectTo: 'ca/dashboard' },

    {
        path: 'ca',
        canActivate: [AuthGuard, CompanyAdminGuard],
        component: LayoutComponent,
        children: [
            {
                path: 'dashboard',
                component: CompanyAdminDashboardComponent,
            },
            {
                path: 'awareness',
                children: [
                    {
                        path: 'campaign',
                        component: AwarenessCampaignComponent,
                    },
                ],
            },
            {
                path: 'donation-campaigns',
                children: [
                    {
                        path: '',
                        component: DonationCampaignsComponent,
                    },
                    {
                        path: ':id',
                        component: DonationCampaignComponent,
                    },
                ],
            },
            {
                path: 'filters',
                component: CompanyFiltersComponent,
            },
            {
                path: 'organizations',
                children: [
                    {
                        path: ':id',
                        component: AssoProfileViewComponent,
                    },
                ],
            },
            {
                path: 'setup',
                component: CompanyAdminSetupComponent,
            },
            {
                path: 'surveys',
                children: [
                    {
                        path: '',
                        component: CompanyAdminSurveysComponent,
                    },
                    {
                        path: ':id',
                        component: CompanyAdminSurveyConfigurationComponent,
                    },
                ],
            },
        ],
    },
];
