import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import {
    AbstractControl,
    FormArray,
    ReactiveFormsModule,
} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { FooterComponent } from 'app/shared/components/footer/footer.component';
import { SurveyAnswersFormService } from 'app/shared/services/form/survey-answers-form.service';
import {
    EAuthUrl,
    EExternalUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { SurveyActions } from 'app/store/survey/survey/survey.action';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject } from 'rxjs';
import { SurveyGenericBlocComponent } from './generic-bloc/generic-bloc.component';
import { SurveyQuestionComponent } from './question/question.component';

@Component({
    selector: 'survey',
    templateUrl: './survey.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        LetDirective,
        TranslocoModule,
        NgIf,
        NgFor,
        SurveyQuestionComponent,
        NgxSkeletonLoaderModule,
        ReactiveFormsModule,
        MatInputModule,
        BslkClassicButtonComponent,
        MatIconModule,
        SurveyGenericBlocComponent,
        FooterComponent,
        LanguagesComponent,
    ],
})
export class SurveyComponent {
    unsubscribeAll: Subject<any> = new Subject<any>();

    hasSubmitted = false;

    survey$ = this.store.select(SurveySelectors.selectSurvey);
    isNotPublished$ = this.store.select(SurveySelectors.selectIsNotPublished);
    isLoadingAnswering$ = this.store.select(
        SurveySelectors.selectIsLoadingAnswering
    );
    mustSpecifyEmail$ = this.store.select(
        SurveySelectors.selectMustSpecifyEmail
    );
    userName$ = this.store.select(UserSelectors.selectDisplayedName);
    isLoggedIn$ = this.store.select(UserSelectors.selectIsLoggedIn);
    hasSentAnswers$ = this.store.select(SurveySelectors.selectHasSentAnswers);
    isActive$ = this.store.select(SurveySelectors.selectIsActive);
    isDone$ = this.store.select(SurveySelectors.selectIsDone);
    showThankYou$ = this.store.select(SurveySelectors.selectShowThankYou);
    results$ = this.store.select(SurveySelectors.selectResults);
    hasResults$ = this.store.select(SurveySelectors.selectHasResults);

    constructor(
        private store: Store,
        private surveyAnswersFormService: SurveyAnswersFormService
    ) {}

    get formGroup() {
        return this.surveyAnswersFormService.getFormGroup;
    }

    get isFormValid() {
        return this.formGroup.valid;
    }

    send() {
        this.hasSubmitted = true;

        if (!this.isFormValid) {
            const emailRequired =
                this.formGroup.controls.email.errors?.required;
            if (emailRequired) {
                const emailElement = document.getElementById('emailInput');
                if (emailElement) {
                    emailElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
                return;
            }

            const firstInvalidQuestionIndex = (
                this.surveyAnswersFormService.getFormGroup.controls
                    .answers as FormArray
            ).controls.findIndex((control: AbstractControl) => control.invalid);

            if (firstInvalidQuestionIndex !== -1) {
                const invalidQuestionElement = document.getElementById(
                    `question-${firstInvalidQuestionIndex}`
                );
                if (invalidQuestionElement) {
                    invalidQuestionElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                    });
                }
            }
            return;
        }

        this.store.dispatch(SurveyActions.sendAnswersRequest());
    }

    signIn(token: string) {
        return UrlHelpers.getAuthUrl(
            EAuthUrl.SignIn,
            UrlHelpers.getExternalUrl(EExternalUrl.Survey, { id: token })
        );
    }

    signOut() {
        return UrlHelpers.getAuthUrl(EAuthUrl.SignOut);
    }
}
