import { NgClass, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BslkAnimatedNumberComponent } from '@bslk/components/animated-number/animated-number.component';
import { BslkColor } from '@bslk/types/bslk-types';
import { TranslocoModule } from '@ngneat/transloco';
import { EUnit, ProjectMetricViewModel } from 'app/api';
import { ShortenNumberPipe } from 'app/shared/pipes/shorten-number.pipe';

@Component({
    selector: 'bslk-business-metric',
    templateUrl: './metric.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        BslkAnimatedNumberComponent,
        NgIf,
        NgClass,
        ShortenNumberPipe,
    ],
})
export class BslkBusinessMetricComponent {
    @Input() metric: ProjectMetricViewModel;
    @Input() color: BslkColor = 'primary';
    @Input() showAnimation = true;

    EUnit = EUnit;

    constructor() {}

    ngOnInit() {}
}
