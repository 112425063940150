import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { ImageUploadDialogInput } from 'app/shared/components/file-upload/file-upload-dialog.component';
import { FileUploadDialogService } from 'app/shared/components/file-upload/file-upload-dialog.service';
import { DonationCampaignCustomizationFormService } from 'app/shared/services/form/donation-campaign-customization-form.service';
import { DonationCampaignCustomizationActions } from 'app/store/donation/donation-campaign-customization/donation-campaign-customization.action';
import { DonationCampaignCustomizationSelectors } from 'app/store/donation/donation-campaign-customization/donation-campaign-customization.selectors';
import { Subject } from 'rxjs';

export type DonationCampaignCustomizeDialogInput = {
    isDefaultCustomization: boolean;
    donationCampaignId?: number;
};

@Component({
    selector: 'donation-campaign-customize-dialog',
    templateUrl: './customize-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        FormsModule,
        FuseAlertComponent,
        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgFor,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class DonationCampaignCustomizeDialogComponent implements OnInit {
    unsubscribeAll: Subject<any> = new Subject<any>();

    isLoading$ = this.store.select(
        DonationCampaignCustomizationSelectors.selectIsLoading
    );

    constructor(
        private dialogRef: MatDialogRef<DonationCampaignCustomizeDialogComponent>,
        private store: Store,
        private formService: DonationCampaignCustomizationFormService,
        private fileUploadDialogService: FileUploadDialogService,
        @Inject(MAT_DIALOG_DATA)
        public data: DonationCampaignCustomizeDialogInput
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    ngOnInit(): void {
        this.store.dispatch(
            DonationCampaignCustomizationActions.loadDonationCampaignCustomizationRequest(
                { donationCampaignId: this.data.donationCampaignId }
            )
        );
    }

    close() {
        this.dialogRef.close();
    }

    submit() {
        if (this.data.isDefaultCustomization) {
            this.store.dispatch(
                DonationCampaignCustomizationActions.updateDonationCampaignCustomizationRequest()
            );
        } else {
            this.store.dispatch(
                DonationCampaignCustomizationActions.createOrUpdateSpecificDonationCampaignCustomizationRequest(
                    { donationCampaignId: this.data.donationCampaignId }
                )
            );
        }
    }

    openFileUploadDialog() {
        this.fileUploadDialogService.open({
            formControl: this.formGroup.controls.imageAsBase64,
            maxFileSize: 2000 * 1024,
        } as ImageUploadDialogInput);
    }
}
