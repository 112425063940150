<div *ngrxLet="survey$ as survey" class="w-full flex flex-col min-h-screen">
    <div
        *ngIf="!(showThankYou$ | async); else showThankYou"
        class="flex grow flex-col items-center py-5"
    >
        <div
            *ngrxLet="results$ as results"
            class="flex flex-col gap-8 p-3 md:p-0 md:w-200"
        >
            <div
                class="p-4 flex flex-col justify-center bg-gradient-to-br from-primary-200 to-warn-200 rounded-xl shadow"
            >
                <div class="flex justify-between">
                    <span class="text-4xl font-semibold">{{
                        survey.name
                    }}</span>

                    <div class="flex gap-2 items-center">
                        <languages></languages>

                        <img
                            class="w-12 h-12"
                            src="assets/images/logo/logo.png"
                            alt="logo"
                        />
                    </div>
                </div>

                <div class="border-t my-2"></div>

                <div class="text-sm flex flex-col gap-1">
                    <div *ngIf="!survey.isQuiz" class="flex gap-2">
                        <mat-icon
                            class="text-current icon-size-5"
                            [svgIcon]="'heroicons_outline:exclamation-circle'"
                        ></mat-icon>
                        {{ "survey.required-questions" | transloco }}
                    </div>
                    <div *ngIf="survey.isAnonymous" class="flex gap-2">
                        <mat-icon
                            class="text-current icon-size-5"
                            [svgIcon]="'feather:user-x'"
                        ></mat-icon>
                        {{ "survey.anonymous-info" | transloco }}
                    </div>
                    <div
                        *ngIf="(isLoggedIn$ | async) && !survey.isAnonymous"
                        class="flex gap-2 items-center"
                    >
                        <mat-icon
                            class="text-current icon-size-5"
                            [svgIcon]="'heroicons_outline:user'"
                        ></mat-icon>
                        <span>
                            {{ "survey.answering-as" | transloco }}&nbsp;<span
                                class="font-bold"
                                >{{ userName$ | async }}
                            </span>
                            <a [href]="signOut()" class="text-xs"
                                >({{ "survey.sign-out" | transloco }})</a
                            ></span
                        >
                    </div>
                </div>
            </div>

            <div
                *ngIf="hasResults$ | async"
                class="w-full flex items-center bg-white justify-center gap-4 rounded-lg shadow p-5"
            >
                <img
                    class="w-36"
                    src="assets/basile-postures/reading.png"
                    alt="Basile"
                />
                <div class="flex flex-col items-center gap-2">
                    <div *ngIf="survey.showNumberOfCorrectAnswers">
                        <span>{{ "survey.your-score" | transloco }} :</span>
                        <span class="font-bold">
                            {{ results.correctAnswersCount }} /
                            {{ results.totalAnswersCount }}</span
                        >
                    </div>
                    <span>{{
                        "survey.thanks-for-participarting" | transloco
                    }}</span>
                    <mat-icon
                        class="w-20 h-20 animate-reward"
                        [svgIcon]="'bslk_solid:trophy'"
                    ></mat-icon>
                </div>
            </div>

            <ng-container *ngIf="isActive$ | async; else notActive">
                <div class="flex flex-col gap-5">
                    <survey-generic-bloc
                        *ngIf="mustSpecifyEmail$ | async"
                        [label]="'survey.email' | transloco"
                        [control]="formGroup.controls.email"
                        [showErrors]="hasSubmitted"
                        [isRequired]="true"
                        [borderColor]="'warn'"
                    >
                        <mat-form-field class="w-full mt-2">
                            <input
                                id="emailInput"
                                [placeholder]="
                                    'survey.email-placeholder' | transloco
                                "
                                autocomplete="email"
                                matInput
                                [formControl]="formGroup.controls.email"
                            />

                            <mat-error
                                *ngIf="
                                    formGroup.controls.email.hasError('email')
                                "
                            >
                                {{ "shared.common.invalid-email" | transloco }}
                            </mat-error>
                        </mat-form-field>

                        <div>
                            <span>{{ "survey.or" | transloco }} </span>
                            <a
                                class="font-bold"
                                [href]="signIn(survey.token)"
                                >{{ "survey.sign-in" | transloco }}</a
                            >
                        </div>
                    </survey-generic-bloc>

                    <survey-question
                        *ngFor="let question of survey.questions; let i = index"
                        [question]="question"
                        [index]="i"
                        [showErrors]="hasSubmitted"
                        [id]="'question-' + i"
                        [showResults]="hasResults$ | async"
                        [isCorrect]="
                            results?.questionsCorrectlyAnswered[question.id]
                        "
                        [correctChoiceIds]="
                            results?.correctAnswers[question.id]
                        "
                    ></survey-question>
                </div>

                <div
                    *ngIf="!(hasSentAnswers$ | async)"
                    class="flex justify-end"
                >
                    <bslk-classic-button
                        (click)="send()"
                        [icon]="'heroicons_outline:paper-airplane'"
                        [isLoading]="isLoadingAnswering$ | async"
                        [isDisabled]="hasSubmitted && !isFormValid"
                        >{{
                            "shared.common.send" | transloco
                        }}</bslk-classic-button
                    >
                </div>
            </ng-container>
        </div>
    </div>

    <bslk-footer></bslk-footer>
</div>

<ng-template #showThankYou>
    <div class="grow flex flex-col justify-center items-center">
        <div
            class="w-fit flex items-center justify-center gap-4 bg-white border rounded-xl shadow p-8"
        >
            <img
                class="w-36"
                src="assets/basile-postures/good-answer.png"
                alt="Basile"
            />
            <p class="text-lg font-semibold max-w-72">
                {{ "survey.thanks-for-answering" | transloco }}
            </p>
        </div>
    </div>
</ng-template>

<ng-template #notActive>
    <div
        *ngIf="(isNotPublished$ | async) && !(isDone$ | async)"
        class="flex justify-center items-center h-full bg-white p-4 rounded-xl shadow"
    >
        <div class="flex flex-col items-center gap-4">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:eye-slash'"
            ></mat-icon>
            <p class="text-lg font-semibold">
                {{ "survey.not-published" | transloco }}
            </p>
        </div>
    </div>
    <div
        *ngIf="isDone$ | async"
        class="flex justify-center items-center h-full bg-white p-4 rounded-xl shadow"
    >
        <div class="flex flex-col items-center gap-4">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:calendar'"
            ></mat-icon>
            <p class="text-lg font-semibold">
                {{ "survey.done" | transloco }}
            </p>
        </div>
    </div>
</ng-template>
