import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { BslkBusinessMetricComponent } from '@bslk-business/components/metric/metric.component';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicCardComponent } from '@bslk/components/cards/classic/classic-card.component';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { FuseCardComponent } from '@fuse/components/card';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { EActivityField } from 'app/api';
import { EnumTagListComponent } from 'app/shared/components/enum-tag-list/enum-tag-list.component';
import { CoverTemplateComponent } from 'app/shared/components/templates/cover-template/cover-template.component';
import { SafePipe } from 'app/shared/pipes/safe.pipe';
import { GoogleMapsApiService } from 'app/shared/services/api/google-maps-api.service';
import { sustainableDevelopmentGoals } from 'app/shared/utils/constants.utils';
import { initializeMapWithLocations } from 'app/shared/utils/google-maps.utils';
import { ProjectActions } from 'app/store/project/project/project.action';
import { ProjectSelectors } from 'app/store/project/project/project.selectors';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { ProjectDonationImpactComponent } from './donation-impact/donation-impact.component';

@Component({
    selector: 'project-view',
    templateUrl: './project-view.component.html',
    styleUrls: ['./project-view.component.scss'],
    standalone: true,
    imports: [
        AsyncPipe,
        BslkBusinessMetricComponent,
        BslkClassicButtonComponent,
        BslkClassicCardComponent,
        BslkTagButtonComponent,
        BslkTagComponent,
        CoverTemplateComponent,
        EnumTagListComponent,
        FuseCardComponent,
        LetDirective,
        MatButtonModule,
        MatChipsModule,
        MatDividerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatTooltipModule,
        NgFor,
        NgIf,
        ProjectDonationImpactComponent,
        SafePipe,
        TranslocoModule,
    ],
})
export class ProjectViewComponent implements OnInit {
    @ViewChild('googleMaps') mapRef: ElementRef;

    @Input() emitOnClose = false;
    @Input() closeFixedPosition = false;
    @Input() canChoose = false;
    @Output() close: EventEmitter<void> = new EventEmitter<void>();
    @Output() choose: EventEmitter<void> = new EventEmitter<void>();

    EActivityField = EActivityField;

    unsubscribeAll: Subject<any> = new Subject<any>();
    showGoogleMaps = false;
    showSolutionDetails = false;
    sustainableDevlopmentGoals = sustainableDevelopmentGoals;

    locationSubscription: Subscription;

    project$ = this.store.select(ProjectSelectors.selectProject);
    projectImagesUrl$ = this.store.select(
        ProjectSelectors.selectProjectImagesUrl
    );
    isLoading$ = this.store.select(ProjectSelectors.selectIsLoading);
    videoUrl$ = this.store.select(ProjectSelectors.selectVideoUrl);
    isAssoAdminOrSuperAdmin$ = this.store.select(
        UserSelectors.selectIsAssoAdminOrSuperAdmin
    );

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private googleMapsApiService: GoogleMapsApiService
    ) {}

    ngOnInit(): void {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                if (params['id']) {
                    this.store.dispatch(
                        ProjectActions.loadProjectRequest({ id: params['id'] })
                    );
                    this.ngAfterViewInit();
                }
            });
    }

    ngAfterViewInit(): void {
        this.locationSubscription = this.store
            .select(ProjectSelectors.selectLocations)
            .subscribe((locations) => {
                if (locations?.length > 0) {
                    this.showGoogleMaps = true;
                    this.googleMapsApiService
                        .loadLibrary({
                            core: true,
                            maps: true,
                            marker: true,
                        })
                        .then((libraries) => {
                            initializeMapWithLocations(
                                libraries,
                                this.mapRef,
                                locations
                            );
                        });
                }
            });
    }

    editProject() {
        this.store.dispatch(ProjectActions.editProject());
    }

    sanitize(text: string) {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }

    getEnumValues(enumObj: any): string[] {
        return Object.values(enumObj);
    }

    onClose() {
        this.close.emit();
    }

    chooseProject() {
        this.choose.emit();
    }

    ngOnDestroy(): void {
        if (this.locationSubscription) {
            this.locationSubscription.unsubscribe();
        }
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }
}
