<div
    [class.border-primary]="index % 2 === 0"
    [class.border-warn]="index % 2 !== 0"
    [formGroup]="group"
    class="p-4 bg-white border-l-8 rounded-lg shadow-lg"
>
    <div class="grid grid-cols-2 gap-4">
        <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
            <mat-label>{{ "survey.question" | transloco }}</mat-label>
            <mat-icon
                matPrefix
                class="icon-size-5"
                [svgIcon]="'heroicons_solid:magnifying-glass'"
            ></mat-icon>
            <input
                matInput
                [autocomplete]="'off'"
                [formControlName]="'question'"
            />
            <mat-error>
                {{ "survey.question-error" | transloco }}
            </mat-error>
        </mat-form-field>
        <mat-form-field class="block" [subscriptSizing]="'dynamic'">
            <mat-label>{{ "survey.question-type" | transloco }}</mat-label>
            <mat-select
                (selectionChange)="typeChange($event)"
                [formControlName]="'type'"
            >
                <mat-option [value]="ESurveyQuestionType.SingleChoice">{{
                    "survey.question-types.single-choice" | transloco
                }}</mat-option>
                <mat-option [value]="ESurveyQuestionType.MultipleChoice">{{
                    "survey.question-types.multiple-choice" | transloco
                }}</mat-option>
                <mat-option [value]="ESurveyQuestionType.ShortAnswer">{{
                    "survey.question-types.short-answer" | transloco
                }}</mat-option>
                <mat-option [value]="ESurveyQuestionType.Paragraph">{{
                    "survey.question-types.paragraph" | transloco
                }}</mat-option>
                <mat-option [value]="ESurveyQuestionType.Rating">{{
                    "survey.question-types.rating" | transloco
                }}</mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="hasChoices">
            <div
                class="flex flex-col gap-2"
                *ngFor="
                    let choiceFormGroup of choicesFormArray.controls;
                    let i = index
                "
            >
                <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                    <mat-label
                        >{{ "survey.choice" | transloco }}
                        {{ i + 1 }}</mat-label
                    >
                    <input
                        matInput
                        [autocomplete]="'off'"
                        [formControl]="choiceFormGroup.controls.choice"
                    />
                    <button mat-icon-button matSuffix (click)="removeChoice(i)">
                        <mat-icon
                            class="mr-1"
                            [svgIcon]="'heroicons_solid:x-mark'"
                        >
                        </mat-icon>
                    </button>
                    <mat-error>
                        {{ "survey.choice-error" | transloco }}
                    </mat-error>
                </mat-form-field>
                <bslk-classic-toggle
                    *ngIf="isQuiz"
                    class="flex"
                    [size]="'very-small'"
                    [choices]="[
                        { text: 'survey.good-answer' | transloco, value: true },
                        { text: 'survey.bad-answer' | transloco, value: false }
                    ]"
                    [control]="choiceFormGroup.controls.isCorrect"
                ></bslk-classic-toggle>
            </div>
        </ng-container>
    </div>

    <bslk-tag-button
        *ngIf="hasChoices"
        class="flex mt-5"
        [icon]="'heroicons_outline:plus'"
        [color]="'primary'"
        (onClick)="addChoice()"
        [size]="'small'"
        >{{ "survey.add-choice" | transloco }}</bslk-tag-button
    >

    <div class="border-t mt-6 mb-4"></div>

    <div class="flex gap-4 justify-between items-center">
        <div class="flex gap-4">
            <span class="font-semibold text-sm">
                {{ "survey.question-number" | transloco }} {{ index + 1 }}
            </span>
            <span class="font-bold text-primary" *ngIf="dynamicError">{{
                dynamicError
            }}</span>
        </div>

        <div class="flex gap-4">
            <bslk-classic-toggle
                *ngIf="!surveyFormGroup.controls.isQuiz.value"
                [size]="'small'"
                [choices]="[
                    { text: 'survey.is-required' | transloco, value: true },
                    { text: 'survey.is-not-required' | transloco, value: false }
                ]"
                [control]="group.controls.isRequired"
            ></bslk-classic-toggle>
            <bslk-icon-button
                (click)="duplicate()"
                [icon]="'heroicons_outline:document-duplicate'"
                [tooltip]="'shared.common.duplicate' | transloco"
            ></bslk-icon-button>
            <bslk-icon-button
                (click)="removeQuestion()"
                [icon]="'heroicons_outline:trash'"
                [tooltip]="'shared.common.remove' | transloco"
            ></bslk-icon-button>
        </div>
    </div>
</div>
