<base-dialog
    (onClose)="close()"
    [title]="'surveys.settings' | transloco"
    [showActions]="true"
    [scrollablePadding]="true"
>
    <div
        class="w-full h-full justify-center flex flex-col gap-4 overflow-y-scroll min-w-200"
        body
    >
        <form [formGroup]="formGroup" class="flex flex-col gap-4">
            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{ "surveys.survey-name" | transloco }}</mat-label>
                <input
                    matInput
                    type="text"
                    [formControlName]="'name'"
                    required
                />
                <mat-error>
                    {{ "surveys.errors.required-survey-name" | transloco }}
                </mat-error>
            </mat-form-field>

            <mat-form-field
                *ngIf="!data.isEditing"
                [subscriptSizing]="'dynamic'"
            >
                <mat-label>{{ "survey.model" | transloco }}</mat-label>
                <mat-select [formControl]="formGroup.controls.model">
                    <mat-option selected [value]="ESurveyModel.NoModel">
                        {{ "surveymodels.NoModel.title" | transloco }}
                    </mat-option>
                    <mat-option [value]="ESurveyModel.CsrPerception">
                        {{ "surveymodels.CsrPerception.title" | transloco }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div class="flex flex-col gap-1">
                <bslk-info-label
                    class="font-bold"
                    [tooltip]="
                        'donationcampaign.link-customization-tooltip'
                            | transloco
                    "
                    >{{
                        "donationcampaign.link-customization" | transloco
                    }}</bslk-info-label
                >
                <mat-form-field
                    class="w-full fuse-mat-emphasized-affix"
                    [subscriptSizing]="'dynamic'"
                >
                    <span class="text-secondary" matPrefix>{{
                        urlPrefix
                    }}</span>
                    <input
                        matInput
                        type="text"
                        [formControlName]="'token'"
                        required
                    />
                    <mat-error
                        *ngIf="
                            formGroup.controls.token.hasError(
                                'invalidUrlSegment'
                            )
                        "
                    >
                        {{ "survey.invalid-url-segment-error" | transloco }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            formGroup.controls.token.hasError('asyncInvalid')
                        "
                    >
                        {{ "surveys.async-invalid-error" | transloco }}
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
                <mat-label>{{ "surveys.end-date" | transloco }}</mat-label>
                <input
                    matInput
                    [matDatepicker]="picker"
                    [matDatepickerFilter]="getDatesFilter"
                    [formControlName]="'endDate'"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="picker"
                ></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>

            <div class="grid grid-cols-2 gap-4">
                <bslk-classic-toggle
                    [isLabelBold]="true"
                    [label]="'surveys.is-published' | transloco"
                    [infoTooltip]="'surveys.is-published-tooltip' | transloco"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isPublished"
                    [value]="formGroup.controls.isPublished.value"
                    [size]="'small'"
                ></bslk-classic-toggle>

                <bslk-classic-toggle
                    [isLabelBold]="true"
                    [label]="'surveys.is-open-to-unregistered' | transloco"
                    [infoTooltip]="
                        'surveys.is-open-to-unregistered-tooltip' | transloco
                    "
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isOpenToUnregistered"
                    [value]="formGroup.controls.isOpenToUnregistered.value"
                    [size]="'small'"
                ></bslk-classic-toggle>

                <bslk-classic-toggle
                    [isLabelBold]="true"
                    [label]="'surveys.is-anonymous' | transloco"
                    [infoTooltip]="'surveys.is-anonymous-tooltip' | transloco"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isAnonymous"
                    [value]="formGroup.controls.isAnonymous.value"
                    [size]="'small'"
                ></bslk-classic-toggle>

                <bslk-classic-toggle
                    [isLabelBold]="true"
                    (valueChange)="onIsQuizChange($event)"
                    [label]="'surveys.is-quiz' | transloco"
                    [infoTooltip]="'surveys.is-quiz-tooltip' | transloco"
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.isQuiz"
                    [value]="formGroup.controls.isQuiz.value"
                    [size]="'small'"
                ></bslk-classic-toggle>

                <bslk-classic-toggle
                    [isLabelBold]="true"
                    *ngIf="formGroup.controls.isQuiz.value"
                    [label]="'surveys.show-correct-answers' | transloco"
                    [infoTooltip]="
                        'surveys.show-correct-answers-tooltip' | transloco
                    "
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.showCorrectAnswers"
                    [value]="formGroup.controls.showCorrectAnswers.value"
                    [size]="'small'"
                ></bslk-classic-toggle>

                <bslk-classic-toggle
                    [isLabelBold]="true"
                    *ngIf="formGroup.controls.isQuiz.value"
                    [label]="
                        'surveys.show-number-of-correct-answers' | transloco
                    "
                    [infoTooltip]="
                        'surveys.show-number-of-correct-answers-tooltip'
                            | transloco
                    "
                    [choices]="[
                        { text: 'shared.common.yes' | transloco, value: true },
                        { text: 'shared.common.no' | transloco, value: false }
                    ]"
                    [control]="formGroup.controls.showNumberOfCorrectAnswers"
                    [value]="
                        formGroup.controls.showNumberOfCorrectAnswers.value
                    "
                    [size]="'small'"
                ></bslk-classic-toggle>
            </div>
        </form>

        <fuse-alert appearance="outline" type="warning" *ngIf="switchedToQuiz">
            {{ "surveys.quiz-warning" | transloco }}
        </fuse-alert>
    </div>

    <div class="flex justify-end" actions>
        <bslk-classic-button
            (click)="submit()"
            [isLoading]="isLoading$ | async"
            [isDisabled]="!isCoreFormValid"
            >{{ "surveys.save" | transloco }}</bslk-classic-button
        >
    </div>
</base-dialog>
