/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SurveyQuestionChoiceViewModel { 
    id?: number;
    dateCreated?: string;
    dateUpdated?: string | null;
    dateArchived?: string | null;
    choice?: string | null;
    isCorrect?: boolean | null;
    language?: string | null;
    surveyQuestionId?: number;
    answeredPercentage?: number | null;
    answeredCount?: number | null;
}

