/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ESurveyQuestionType = 'ShortAnswer' | 'MultipleChoice' | 'SingleChoice' | 'Rating' | 'Paragraph';

export const ESurveyQuestionType = {
    ShortAnswer: 'ShortAnswer' as ESurveyQuestionType,
    MultipleChoice: 'MultipleChoice' as ESurveyQuestionType,
    SingleChoice: 'SingleChoice' as ESurveyQuestionType,
    Rating: 'Rating' as ESurveyQuestionType,
    Paragraph: 'Paragraph' as ESurveyQuestionType
};

