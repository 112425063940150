import { Clipboard } from '@angular/cdk/clipboard';
import { AsyncPipe, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { CompanyAdminSurveySettingsDialogService } from '../../settings-dialog/settings-dialog.service';

@Component({
    selector: 'survey-share-link',
    templateUrl: './share-link.component.html',
    standalone: true,
    imports: [TranslocoModule, MatIconModule, NgIf, LetDirective, AsyncPipe],
})
export class SurveyShareLinkComponentt {
    isCopied = false;

    surveyUrl$ = this.store.select(SurveySelectors.selectSurveyUrl);

    isDone$ = this.store.select(SurveySelectors.selectSurveyIsDone);
    isNotPublished$ = this.store.select(
        SurveySelectors.selectSurveyIsNotPublished
    );

    constructor(
        private store: Store,
        private clipboard: Clipboard,
        private surveySettingsDialogService: CompanyAdminSurveySettingsDialogService
    ) {}

    copyToClipboard(url: string) {
        this.isCopied = true;
        setTimeout(() => {
            this.isCopied = false;
        }, 3000);
        this.clipboard.copy(url);
    }

    openSettings() {
        this.surveySettingsDialogService.open({
            isEditing: true,
            isFromEntityPage: true,
        });
    }
}
