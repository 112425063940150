import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
    name: 'shortenNumber',
    standalone: true,
})
export class ShortenNumberPipe implements PipeTransform {
    constructor(private translocoService: TranslocoService) {}

    transform(value: number): string {
        const locale = this.translocoService.getActiveLang();

        if (value < 1000) {
            return value.toLocaleString(locale);
        }

        if (value >= 1000 && value < 1000000) {
            return (value / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        }

        if (value >= 1000000 && value < 1000000000) {
            return (value / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }

        if (value >= 1000000000) {
            return (value / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        }

        return value.toLocaleString(locale);
    }
}
