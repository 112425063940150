import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkChartComponent } from '@bslk/components/charts/bslk-chart/bslk-chart.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SurveyQuestionViewModel } from 'app/api';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { SurveyGenericDetailsComponent } from '../generic-details/generic-details.component';

@Component({
    selector: 'survey-multiple-choice-details',
    templateUrl: './multiple-choice-details.component.html',
    standalone: true,
    imports: [
        BslkChartComponent,
        TranslocoModule,
        MatIconModule,
        SurveyGenericDetailsComponent,
    ],
})
export class SurveyMultipleChoiceDetailsComponent implements OnInit {
    @Input() question: SurveyQuestionViewModel;
    @Input() index: number;

    chartOptions: Partial<ApexOptions>;
    series: ApexAxisChartSeries | number[];

    constructor(private translocoService: TranslocoService) {}

    ngOnInit() {
        const numChoices = this.question.choices.length;
        const calculateHeight = (num: number) => {
            const baseHeight = 120;
            const growthFactor = 80;
            return baseHeight + Math.log1p(num) * growthFactor;
        };
        this.series = [
            {
                name: this.translocoService.translate('survey.vote-count'),
                data: this.question.choices.map(
                    (choice) => choice.answeredCount
                ),
            },
        ];

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: calculateHeight(numChoices),
            },
            plotOptions: {
                bar: {
                    borderRadius: 8,
                    borderRadiusApplication: 'end',
                    horizontal: true,
                    barHeight: '80%',
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val: number, opts: any) => {
                    const percentage =
                        this.question.choices[opts.dataPointIndex]
                            .answeredPercentage;
                    const count = val;
                    return `${percentage.toFixed(0)}% (${count})`;
                },
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                },
            },
            colors: ['#8490c8'],
            xaxis: {
                categories: this.question.choices.map(
                    (choice) => choice.choice
                ),
                labels: {
                    show: true,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    },
                    formatter: (value: string) => {
                        const numericValue = parseFloat(value);
                        return Number.isInteger(numericValue)
                            ? value
                            : Math.round(numericValue).toString();
                    },
                },
                tickAmount: Math.max(
                    ...this.question.choices.map((c) => c.answeredCount)
                ),
            },

            yaxis: {
                labels: {
                    show: true,
                    padding: 100,
                    style: {
                        fontSize: '18px',
                        fontWeight: 'semibold',
                    },
                },
            },
            grid: {
                show: false,
            },
        };
    }
}
