/**
 * Colibri.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ESurveyState = 'NotPublished' | 'Active' | 'Done';

export const ESurveyState = {
    NotPublished: 'NotPublished' as ESurveyState,
    Active: 'Active' as ESurveyState,
    Done: 'Done' as ESurveyState
};

