import { AsyncPipe, NgFor } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { SurveyQuestionViewModel } from 'app/api';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { SurveyGenericDetailsComponent } from '../generic-details/generic-details.component';

@Component({
    selector: 'survey-open-answer-details',
    templateUrl: './open-answer-details.component.html',
    standalone: true,
    imports: [
        NgFor,
        MatTooltipModule,
        AsyncPipe,
        SurveyGenericDetailsComponent,
    ],
})
export class SurveyOpenAnswerDetailsComponent implements OnInit {
    @Input() question: SurveyQuestionViewModel;
    @Input() index: number;

    isAnonymous$ = this.store.select(SurveySelectors.selectIsAnonymous);

    get answers() {
        return this.question.userAnswers;
    }

    constructor(private store: Store) {}

    ngOnInit() {}
}
