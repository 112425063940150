<div *ngrxLet="survey$ as survey" class="flex flex-col gap-5">
    <survey-participants *ngIf="!survey.isAnonymous"></survey-participants>
    <div *ngFor="let question of survey.questions; let i = index">
        <ng-container [ngSwitch]="question.type">
            <div *ngSwitchCase="ESurveyQuestionType.ShortAnswer">
                <survey-open-answer-details
                    [question]="question"
                    [index]="i"
                ></survey-open-answer-details>
            </div>
            <div *ngSwitchCase="ESurveyQuestionType.Paragraph">
                <survey-open-answer-details
                    [question]="question"
                    [index]="i"
                ></survey-open-answer-details>
            </div>
            <div *ngSwitchCase="ESurveyQuestionType.Rating">
                <survey-rating-details
                    [question]="question"
                    [index]="i"
                ></survey-rating-details>
            </div>
            <div *ngSwitchCase="ESurveyQuestionType.MultipleChoice">
                <survey-multiple-choice-details
                    [question]="question"
                    [index]="i"
                ></survey-multiple-choice-details>
            </div>
            <div *ngSwitchCase="ESurveyQuestionType.SingleChoice">
                <survey-single-choice-details
                    [question]="question"
                    [index]="i"
                ></survey-single-choice-details>
            </div>
        </ng-container>
    </div>
</div>
