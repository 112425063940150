import { NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FormArray } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { SurveyFormService } from 'app/shared/services/form/survey-form.service';
import { SurveyActions } from 'app/store/survey/survey/survey.action';
import { CompanyAdminSurveyConfigurationQuestionComponent } from './question/question.component';

@Component({
    selector: 'company-admin-survey-configuration-questions',
    templateUrl: './questions.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        TranslocoModule,
        CompanyAdminSurveyConfigurationQuestionComponent,
        NgFor,
        NgIf,
        BslkClassicButtonComponent,
        BslkTagComponent,
    ],
})
export class CompanyAdminSurveyConfigurationQuestionsComponent {
    constructor(private store: Store, private formService: SurveyFormService) {}

    get formArray() {
        return this.formService.questionsFormArray as FormArray;
    }

    get isSaveDisabled() {
        return !this.isDirty || this.isInvalid;
    }

    get isDirty() {
        return this.formArray.dirty;
    }

    get isInvalid() {
        return (
            this.formArray.invalid ||
            Object.keys(this.formService.dynamicQuestionsErrors).length > 0
        );
    }

    addQuestion() {
        this.formArray.push(this.formService.createQuestion());
        setTimeout(() => {
            window.scrollTo({
                left: 0,
                top: document.body.scrollHeight,
                behavior: 'smooth',
            });
        });
    }

    save() {
        if (this.isSaveDisabled) {
            return;
        }
        this.store.dispatch(
            SurveyActions.updateSurveyRequest({ updateOnlyCore: false })
        );
    }
}
