import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SurveyService } from 'app/api';
import { CompanyAdminSurveySettingsDialogService } from 'app/modules/company-admin/surveys/settings-dialog/settings-dialog.service';
import { SurveyFormService } from 'app/shared/services/form/survey-form.service';
import { tap } from 'rxjs';
import {
    createCreateItemEffect,
    createDeleteItemEffect,
    createLoadItemRequestEffect,
    createLoadListRequestEffect,
    createReloadEffect,
    createSelectedItemEffect,
    createStartCreationEffect,
    createUpdateSelectedItemEffect,
} from '../../shared/ressource-list/ressource-list-effects-creator';
import { SurveyListActions } from './survey-list.actions';
import { SurveyListSelectors } from './survey-list.selectors';

@Injectable()
export class SurveyListEffects {
    loadSurveys$ = createLoadListRequestEffect(
        this.store,
        this.actions$,
        this.surveyService,
        SurveyListActions,
        SurveyListSelectors,
        this.surveyService.surveyGet
    );

    loadSurvey$ = createLoadItemRequestEffect(
        this.store,
        this.actions$,
        this.surveyService,
        SurveyListActions,
        SurveyListSelectors,
        this.surveyService.surveyIdGet
    );

    reload$ = createReloadEffect(this.actions$, SurveyListActions);

    deleteMission$ = createDeleteItemEffect(
        this.surveyService,
        this.actions$,
        SurveyListActions,
        'survey-delete',
        this.surveyService.surveyDelete
    );

    update$ = createUpdateSelectedItemEffect(
        this.surveyService,
        this.actions$,
        SurveyListActions,
        this.surveyFormService,
        'survey-update',
        this.surveyService.surveyPut
    );

    startCreation$ = createStartCreationEffect(
        this.actions$,
        SurveyListActions,
        this.surveyFormService
    );

    create$ = createCreateItemEffect(
        this.surveyService,
        this.actions$,
        SurveyListActions,
        this.surveyFormService,
        'survey-create',
        this.surveyService.surveyPost
    );

    closeDialogOnCreateSuccess$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(
                    SurveyListActions.CreateItemSuccess,
                    SurveyListActions.UpdateSelectedItemSuccess
                ),
                tap(() => {
                    this.surveySettingsDialogService?.close();
                })
            ),
        { dispatch: false }
    );

    itemSelected$ = createSelectedItemEffect(
        this.store,
        this.actions$,
        SurveyListActions,
        SurveyListSelectors,
        this.surveyFormService
    );

    constructor(
        private actions$: Actions,
        private store: Store,
        private surveyService: SurveyService,
        private surveyFormService: SurveyFormService,
        private surveySettingsDialogService: CompanyAdminSurveySettingsDialogService
    ) {}
}
