<survey-generic-bloc
    [label]="question.question"
    [group]="formGroup"
    [showErrors]="showErrors"
    [isRequired]="question.isRequired"
    [borderColor]="index % 2 !== 0 ? 'warn' : 'primary'"
>
    <ng-container [formGroup]="formGroup" [ngSwitch]="question.type">
        <!-- Short Answer -->
        <div *ngSwitchCase="ESurveyQuestionType.ShortAnswer">
            <mat-form-field class="w-full mt-2">
                <input matInput [formControlName]="'openAnswer'" />
            </mat-form-field>
            <bslk-length-info
                [maxLength]="150"
                [text]="formGroup.controls.openAnswer.value"
            ></bslk-length-info>
        </div>

        <!-- Paragraph -->
        <div *ngSwitchCase="ESurveyQuestionType.Paragraph">
            <mat-form-field class="w-full mt-2">
                <textarea
                    class="h-20"
                    matInput
                    [formControlName]="'openAnswer'"
                ></textarea>
            </mat-form-field>
            <bslk-length-info
                [maxLength]="1000"
                [text]="formGroup.controls.openAnswer.value"
            ></bslk-length-info>
        </div>

        <!-- Multiple Choice -->
        <div
            class="flex flex-col gap-1"
            *ngSwitchCase="ESurveyQuestionType.MultipleChoice"
        >
            <mat-checkbox
                *ngFor="let choice of question.choices"
                class="rounded"
                [checked]="isChecked(choice.id)"
                [ngClass]="{
                    'bg-tertiary-200': showResults && correctChoiceIds?.includes(choice.id),
                }"
                (change)="onMultipleChoiceChange($event, choice.id)"
            >
                {{ choice.choice }}
            </mat-checkbox>
        </div>

        <!-- Single Choice -->
        <div *ngSwitchCase="ESurveyQuestionType.SingleChoice">
            <mat-radio-group
                class="flex flex-col gap-1"
                [value]="selectedSingleChoice"
                (change)="onSingleChoiceChange($event)"
            >
                <mat-radio-button
                    class="rounded"
                    [ngClass]="{
                    'bg-tertiary-200': showResults && correctChoiceIds?.includes(choice.id),
                }"
                    *ngFor="let choice of question.choices"
                    [value]="choice.id"
                >
                    {{ choice.choice }}
                </mat-radio-button>
            </mat-radio-group>
        </div>

        <!-- Rating -->
        <div *ngSwitchCase="ESurveyQuestionType.Rating">
            <bslk-star-rating
                [control]="formGroup.controls.rating"
                [maxRating]="5"
                [minLabel]="'survey.worst-rating' | transloco"
                [maxLabel]="'survey.best-rating' | transloco"
            ></bslk-star-rating>
        </div>
    </ng-container>

    <div *ngIf="showResults" actions>
        <div
            *ngIf="isCorrect"
            class="flex font-bold items-center text-tertiary gap-2"
        >
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:check'"
            ></mat-icon>
            <span>{{ "survey.correct" | transloco }}</span>
        </div>
        <div
            *ngIf="!isCorrect"
            class="flex font-bold items-center text-primary gap-2"
        >
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:x-mark'"
            ></mat-icon>
            <span>{{ "survey.incorrect" | transloco }}</span>
        </div>
    </div>
</survey-generic-bloc>
