import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ESurveyQuestionType, SurveyQuestionViewModel } from 'app/api';

export enum ESurveyModel {
    NoModel,
    CsrPerception,
}

@Injectable({
    providedIn: 'root',
})
export class SurveyModelBuilderService {
    constructor(private translocoService: TranslocoService) {}

    buildSurveyModel(model: ESurveyModel): SurveyQuestionViewModel[] {
        switch (model) {
            case ESurveyModel.CsrPerception:
                return this.buildCsrPerceptionModel();
            default:
                return [];
        }
    }

    private buildCsrPerceptionModel(): SurveyQuestionViewModel[] {
        const questions: SurveyQuestionViewModel[] = [
            {
                question: this.translocoService.translate(
                    'surveymodels.CsrPerception.q1'
                ),
                type: ESurveyQuestionType.SingleChoice,
                choices: [
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.yes'
                        ),
                    },
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.no'
                        ),
                    },
                ],
                isRequired: true,
            },
            {
                question: this.translocoService.translate(
                    'surveymodels.CsrPerception.q2'
                ),
                type: ESurveyQuestionType.Rating,
            },
            {
                question: this.translocoService.translate(
                    'surveymodels.CsrPerception.q3'
                ),
                type: ESurveyQuestionType.SingleChoice,
                choices: [
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.yes'
                        ),
                    },
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.no'
                        ),
                    },
                ],
                isRequired: true,
            },
            {
                question: this.translocoService.translate(
                    'surveymodels.CsrPerception.q4'
                ),
                type: ESurveyQuestionType.SingleChoice,
                choices: [
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.yes'
                        ),
                    },
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.no'
                        ),
                    },
                ],
                isRequired: true,
            },
            {
                question: this.translocoService.translate(
                    'surveymodels.CsrPerception.q5'
                ),
                type: ESurveyQuestionType.SingleChoice,
                choices: [
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.yes'
                        ),
                    },
                    {
                        choice: this.translocoService.translate(
                            'surveymodels.common.no'
                        ),
                    },
                ],
                isRequired: true,
            },
        ];

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q6'
            ),
            type: ESurveyQuestionType.SingleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.common.yes'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.common.no'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q7'
            ),
            type: ESurveyQuestionType.MultipleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q7.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q7.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q7.choice3'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q8'
            ),
            type: ESurveyQuestionType.SingleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q8.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q8.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q8.choice3'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q8.choice4'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q9'
            ),
            type: ESurveyQuestionType.MultipleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q9.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q9.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q9.choice3'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q9.choice4'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q9.choice5'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q10'
            ),
            type: ESurveyQuestionType.MultipleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice3'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice4'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice5'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice6'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice7'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice8'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice9'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice10'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q10.choice11'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q11'
            ),
            type: ESurveyQuestionType.MultipleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice3'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice4'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice5'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice6'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice7'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice8'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice9'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice10'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice11'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q11.choice12'
                    ),
                },
            ],
            isRequired: true,
        });

        questions.push({
            question: this.translocoService.translate(
                'surveymodels.CsrPerception.q12'
            ),
            type: ESurveyQuestionType.MultipleChoice,
            choices: [
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q12.choice1'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q12.choice2'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q12.choice3'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q12.choice4'
                    ),
                },
                {
                    choice: this.translocoService.translate(
                        'surveymodels.CsrPerception.q12.choice5'
                    ),
                },
            ],
            isRequired: true,
        });

        return questions;
    }
}
