import { Route } from '@angular/router';
import { LayoutComponent } from 'app/layout/layout.component';
import { ShareCertificateComponent } from './donation-gift/donation-certificate/share-certificate/share-certificate.component';
import { DonationGiftComponent } from './donation-gift/donation-gift.component';
import { ThankYouComponent } from './donation-gift/thank-you/thank-you.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SurveyAuthGuard } from './survey/guard/guard.component';
import { SurveyComponent } from './survey/survey.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';

export const externalRoutes: Route[] = [
    { path: 'ext', pathMatch: 'full', redirectTo: 's/dual-quiz' },
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'd/:token',
                component: DonationGiftComponent,
                data: { isPreview: false },
            },
            {
                path: 'd-preview/:token',
                component: DonationGiftComponent,
                data: { isPreview: true },
            },
            {
                path: 'thank-you',
                children: [
                    {
                        path: '',
                        component: ThankYouComponent,
                    },
                    {
                        path: ':id',
                        component: ThankYouComponent,
                    },
                ],
            },
            {
                path: 's/:token',
                component: SurveyComponent,
                canActivate: [SurveyAuthGuard],
            },
            {
                path: 'share-certificate',
                component: ShareCertificateComponent,
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: 'unsubscribe/:email',
                component: UnsubscribeComponent,
            },
        ],
    },
];
