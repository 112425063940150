import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUserSessionFromLocalStorage } from 'app/shared/utils/local-storage.utils';
import { SurveyActions } from 'app/store/survey/survey/survey.action';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { UserActions } from 'app/store/user/user/user.actions';
import { filter, map, take, tap } from 'rxjs';

export const SurveyAuthGuard: CanActivateFn = (_, state) => {
    const router: Router = inject(Router);
    const store: Store = inject(Store);

    const userSession = getUserSessionFromLocalStorage();

    const token = state.url.split('/s/')[1];
    if (!token) {
        return false;
    }

    if (userSession && new Date(userSession.expirationDate) > new Date()) {
        store.dispatch(UserActions.alreadySignedIn({ session: userSession }));
    }

    return store.select(SurveySelectors.selectSurvey).pipe(
        tap((survey) => {
            if (!survey || survey.token !== token) {
                store.dispatch(
                    SurveyActions.loadSurveyRequestByToken({ token })
                );
            }
        }),
        filter((survey) => !!survey && survey.token === token),
        take(1),
        map((survey) => {
            if (!survey.isOpenToUnregistered && !userSession) {
                const redirectURL = `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`sign-in?${redirectURL}`);
                return urlTree;
            }
            return true;
        })
    );
};
