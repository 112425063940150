<div
    class="bg-white flex flex-col p-5 border-l-8 rounded-lg shadow transition-all duration-300"
    [class.border-primary]="index % 2 === 0"
    [class.border-warn]="index % 2 !== 0"
>
    <div class="flex justify-between items-center mb-2">
        <div class="flex items-center gap-2">
            <span class="text-xl">{{ question.question }}</span>
            <span class="text-sm font-bold mt-0.5"
                >{{ answersCount }} {{ "survey.answers" | transloco }}</span
            >
        </div>

        <ng-content select="[actions]"></ng-content>
    </div>

    <ng-content></ng-content>
</div>
