import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormArray, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
    MatCheckboxChange,
    MatCheckboxModule,
} from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { BslkLengthInfoComponent } from '@bslk/components/form/length-info/length-info.component';
import { BslkStarRatingComponent } from '@bslk/components/form/rating/star-rating.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ESurveyQuestionType, SurveyQuestionViewModel } from 'app/api';
import { SurveyAnswersFormService } from 'app/shared/services/form/survey-answers-form.service';
import { SurveyGenericBlocComponent } from '../generic-bloc/generic-bloc.component';

@Component({
    selector: 'survey-question',
    templateUrl: './question.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        BslkStarRatingComponent,
        BslkLengthInfoComponent,
        NgSwitchCase,
        MatIconModule,
        NgSwitch,
        MatCheckboxModule,
        NgFor,
        NgClass,
        NgIf,
        SurveyGenericBlocComponent,
    ],
})
export class SurveyQuestionComponent {
    @Input() index: number;
    @Input() question: SurveyQuestionViewModel;
    @Input() showErrors: boolean;
    @Input() showResults: boolean;
    @Input() isCorrect: boolean;
    @Input() correctChoiceIds: number[];

    ESurveyQuestionType = ESurveyQuestionType;

    constructor(private surveyAnswersFormService: SurveyAnswersFormService) {}

    get formGroup(): FormGroup {
        const answersFormArray = this.surveyAnswersFormService.getFormGroup
            .controls.answers as FormArray;
        return answersFormArray.at(this.index) as FormGroup;
    }

    get selectedSingleChoice(): number | null {
        return this.formGroup.get('surveyQuestionChoiceIds')?.value[0] || null;
    }

    isChecked(choiceId: number): boolean {
        const choiceIds =
            this.formGroup.get('surveyQuestionChoiceIds')?.value || [];
        return choiceIds.includes(choiceId);
    }

    onMultipleChoiceChange(event: MatCheckboxChange, choiceId: number): void {
        const choiceIdsControl = this.formGroup.get('surveyQuestionChoiceIds');

        if (!choiceIdsControl) return;

        const currentChoices = choiceIdsControl.value || [];
        if (event.checked) {
            choiceIdsControl.setValue([...currentChoices, choiceId]);
        } else {
            choiceIdsControl.setValue(
                currentChoices.filter((id: number) => id !== choiceId)
            );
        }
    }

    onSingleChoiceChange(event: any): void {
        const choiceIdsControl = this.formGroup.get('surveyQuestionChoiceIds');
        if (choiceIdsControl) {
            choiceIdsControl.setValue([event.value]);
        }
    }
}
