import { Component, Input } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { SurveyQuestionViewModel } from 'app/api';

@Component({
    selector: 'survey-generic-details',
    templateUrl: './generic-details.component.html',
    standalone: true,
    imports: [TranslocoModule],
})
export class SurveyGenericDetailsComponent {
    @Input() question: SurveyQuestionViewModel;
    @Input() index: number;

    get answersCount() {
        return this.question.userAnswers.length;
    }
}
