<div
    class="bg-white flex flex-col p-5 rounded-lg shadow transition-all duration-300"
    [class.border-primary]="borderColor === 'primary'"
    [class.border-warn]="borderColor === 'warn'"
    [class.border-2]="hasError"
    [class.border-l-8]="!hasError"
>
    <div class="flex justify-between">
        <span class="text-xl">{{ label }}</span>
        <mat-icon
            *ngIf="isRequired"
            class="text-current"
            [svgIcon]="'heroicons_outline:exclamation-circle'"
        ></mat-icon>
        <ng-content select="[actions]"></ng-content>
    </div>

    <ng-content></ng-content>

    <div
        *ngIf="isRequiredNotMet && showErrors"
        class="flex items-center gap-2 mt-5 font-bold text-primary"
    >
        <mat-icon
            class="text-current"
            [svgIcon]="'heroicons_outline:exclamation-circle'"
        ></mat-icon>
        {{ "survey.required-question" | transloco }}
    </div>
</div>
