<div class="bg-white rounded-2xl shadow-lg flex flex-col gap-3 p-6">
    <div class="flex justify-between items-center">
        <div class="flex items-center gap-2">
            <mat-icon [svgIcon]="'feather:share-2'"></mat-icon>
            <span class="font-bold text-2xl">{{
                "survey.share-link" | transloco
            }}</span>
        </div>
        <div
            (click)="openSettings()"
            class="flex gap-2 hover:text-warn transition-all duration-500 cursor-pointer"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:cog-6-tooth'"
                class="text-current"
            ></mat-icon>
            <span class="font-bold">{{ "survey.settings" | transloco }}</span>
        </div>
    </div>

    <div
        *ngrxLet="surveyUrl$ as surveyUrl"
        class="w-full min-w-100 xxl:min-w-140"
    >
        <div class="relative">
            <input
                type="text"
                class="col-span-6 bg-gray-50 border border-gray-300 text-gray-500 text-sm rounded-lg block w-full px-2.5 py-4"
                [value]="surveyUrl"
                disabled
                readonly
            />
            <button
                (click)="copyToClipboard(surveyUrl)"
                class="absolute end-2.5 top-1/2 -translate-y-1/2 text-gray-90 hover:bg-gray-100 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-gray-200 border"
            >
                <span *ngIf="!isCopied" class="inline-flex items-center gap-2">
                    <mat-icon
                        class="icon-size-4"
                        [svgIcon]="'feather:clipboard'"
                    ></mat-icon>
                    <span class="text-xs font-semibold">{{
                        "survey.copy" | transloco
                    }}</span>
                </span>
                <span
                    *ngIf="isCopied"
                    class="inline-flex items-center gap-2 text-tertiary"
                >
                    <mat-icon
                        class="icon-size-4 text-current"
                        [svgIcon]="'feather:check'"
                    ></mat-icon>
                    <span class="text-xs font-semibold text-tertiary">{{
                        "survey.copied" | transloco
                    }}</span>
                </span>
            </button>
        </div>
        <div
            *ngIf="(isDone$ | async) || (isNotPublished$ | async)"
            class="flex items-center gap-1 text-sm mt-2 text-warn"
        >
            <mat-icon
                [svgIcon]="'heroicons_outline:exclamation-triangle'"
                class="text-current icon-size-5"
            ></mat-icon>
            <span *ngIf="isDone$ | async">
                {{ "survey.survey-done" | transloco }}
            </span>
            <span *ngIf="isNotPublished$ | async">
                {{ "survey.survey-not-published" | transloco }}
            </span>
        </div>
    </div>
</div>
