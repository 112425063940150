import { NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { BslkMenuComponent } from '@bslk/components/menu/menu.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ESurveyState, SurveyViewModel } from 'app/api';
import {
    ECompanyAdminUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { SurveyListActions } from 'app/store/survey/survey-list/survey-list.actions';
import { CompanyAdminSurveySettingsDialogService } from '../settings-dialog/settings-dialog.service';

@Component({
    selector: 'company-admin-survey-card',
    templateUrl: './survey-card.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        BslkTagComponent,
        BslkMenuComponent,
        NgSwitchCase,
        NgSwitch,
    ],
})
export class SurveyCardComponent {
    ESurveyState = ESurveyState;

    @Input() survey: SurveyViewModel;

    constructor(
        private store: Store,
        private router: Router,
        private surveySettingsDialogService: CompanyAdminSurveySettingsDialogService
    ) {}

    open() {
        this.router.navigateByUrl(
            UrlHelpers.getCompanyAdminUrl(
                ECompanyAdminUrl.CompanyAdminSurveyConfigurationPage,
                { id: this.survey.id }
            )
        );
    }

    onMenuAction(event: string) {
        if (event === 'modify') {
            this.surveySettingsDialogService.open({
                isEditing: true,
                editingId: this.survey.id,
                isFromEntityPage: false,
            });
        } else if (event === 'archive') {
            this.store.dispatch(
                SurveyListActions.DeleteItem({ id: this.survey.id })
            );
        }
    }
}
