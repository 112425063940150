import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkColor, BslkSize } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-tag-button',
    templateUrl: './tag-button.component.html',
    standalone: true,
    imports: [
        MatIconModule,
        NgIf,
        NgClass,
        MatProgressSpinnerModule,
        MatTooltipModule,
    ],
})
export class BslkTagButtonComponent implements OnInit {
    @Input() icon: string;
    @Input() number: number;
    @Input() size: BslkSize = 'medium';
    @Input() color: BslkColor = 'warn';
    @Input() isLoading = false;
    @Input() isDisabled = false;
    @Input() isDisabledTooltip: string;

    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    ngOnInit() {}

    buttonClick() {
        if (this.isLoading || this.isDisabled) {
            return;
        }

        this.onClick.emit();
    }
}
