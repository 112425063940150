import { DatePipe, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';

@Component({
    selector: 'survey-participants',
    templateUrl: './participants.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        MatIconModule,
        NgFor,
        NgIf,
        LetDirective,
        DatePipe,
    ],
})
export class SurveyParticipantsComponent {
    survey$ = this.store.select(SurveySelectors.selectSurvey);

    constructor(private store: Store) {}
}
