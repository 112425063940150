import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState, RessourceListsState } from '../..';
import { IResource } from '../resource/resource-list.state';
import {
    RessourceListFilters,
    resourceCreationId,
} from './ressource-list-state';

export interface RessourceListSelectors<T> {
    selectIsLoading: MemoizedSelector<AppState, boolean, (s1: any) => boolean>;
    selectData: MemoizedSelector<AppState, T[], (s1: any) => T[]>;
    selectIsItemSelected: MemoizedSelector<
        AppState,
        boolean,
        (s1: any) => boolean
    >;
    selectSelectedItem: MemoizedSelector<
        AppState,
        T | undefined,
        (s1: any) => T | undefined
    >;
    selectFilters: MemoizedSelector<
        AppState,
        RessourceListFilters,
        (s1: any) => RessourceListFilters
    >;
    selectPageSize: MemoizedSelector<AppState, number, (s1: any) => number>;
    selectTotalCount: MemoizedSelector<AppState, number, (s1: any) => number>;
    selectTotalPages: MemoizedSelector<AppState, number, (s1: any) => number>;
    selectPageIndex: MemoizedSelector<AppState, number, (s1: any) => number>;
    selectIsCreating: MemoizedSelector<AppState, boolean, (s1: any) => boolean>;
    selectProperties: MemoizedSelector<
        AppState,
        { [key: string]: string },
        (s1: any) => { [key: string]: string }
    >;
    selectIsInitialized: MemoizedSelector<
        AppState,
        boolean,
        (s1: any) => boolean
    >;
}

export function createRessourceListSelectors<T extends IResource>(
    stateKey: keyof RessourceListsState
): RessourceListSelectors<T> {
    const selectState = (appState: AppState) => appState[stateKey];
    const selectIsLoading = createSelector(
        selectState,
        (state) => state.isLoading
    );
    const selectData = createSelector(selectState, (state) => state.data);
    const selectSelectedItem = createSelector(selectState, (state) =>
        state.data.find((item) => item?.id === state.selectedItemId)
    );
    const selectIsItemSelected = createSelector(
        selectSelectedItem,
        (itemSelected) => itemSelected !== undefined && itemSelected !== null
    );
    const selectFilters = createSelector(selectState, (state) => state.filters);
    const selectPageIndex = createSelector(
        selectState,
        (state) => state.pageIndex
    );
    const selectTotalPages = createSelector(
        selectState,
        (state) => state.totalPages
    );
    const selectTotalCount = createSelector(
        selectState,
        (state) => state.totalCount
    );
    const selectPageSize = createSelector(
        selectState,
        (state) => state.pageSize
    );
    const selectIsCreating = createSelector(selectState, (state) =>
        state.data.map((d) => d.id).includes(resourceCreationId)
    );
    const selectProperties = createSelector(
        selectState,
        (state) => state.properties
    );
    const selectIsInitialized = createSelector(
        selectState,
        (state) => state.isInitialized
    );

    return {
        selectIsLoading,
        selectData,
        selectFilters,
        selectPageIndex,
        selectTotalPages,
        selectTotalCount,
        selectPageSize,
        selectSelectedItem,
        selectIsItemSelected,
        selectIsCreating,
        selectProperties,
        selectIsInitialized,
    };
}
