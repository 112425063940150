import { ActionCreator, createAction, props } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { IResourceList, ResourceListFilter } from './ressource-list-state';

export function createRessourceListActionGroup<
    T extends { id?: number },
    TList extends IResourceList<T>
>(sourceName: any): RessourceListActions<T, TList> {
    return {
        Initialize: createAction(`[${sourceName}] Initialize`),
        InitializeWithFilters: createAction(
            `[${sourceName}] Initialize With Filters`,
            props<{ filters: Array<{ key: ResourceListFilter; value: any }> }>()
        ),
        HardResetWithFilters: createAction(
            `[${sourceName}] Hard Reset With Filters`,
            props<{ filters: Array<{ key: ResourceListFilter; value: any }> }>()
        ),
        LoadListRequest: createAction(`[${sourceName}] Load List Request`),
        LoadListSuccess: createAction(
            `[${sourceName}] Load List Success`,
            props<{ response: TList }>()
        ),
        LoadListFailure: createAction(
            `[${sourceName}] Load List Failure`,
            props<{ error: string }>()
        ),
        LoadItemRequest: createAction(
            `[${sourceName}] Load Item Request`,
            props<{ id: number }>()
        ),
        LoadItemRequestSuccess: createAction(
            `[${sourceName}] Load Item Request Success`,
            props<{ response: T }>()
        ),
        LoadItemRequestFailure: createAction(
            `[${sourceName}] Load Item Request Failure`
        ),
        FilterListRequest: createAction(
            `[${sourceName}] Filter List Request`,
            props<{ filters: Array<{ key: ResourceListFilter; value: any }> }>()
        ),
        SelectItem: createAction(
            `[${sourceName}] Select Item`,
            props<{ id: number }>()
        ),
        UnselectItems: createAction(`[${sourceName}] Unselect Item`),
        UpdateSelectedItem: createAction(
            `[${sourceName}] Update Selected Item`
        ),
        UpdateSelectedItemSuccess: createAction(
            `[${sourceName}] Update Selected Item Success`
        ),
        UpdateSelectedItemFail: createAction(
            `[${sourceName}] Update Selected Item Fail`
        ),
        StartCreation: createAction(`[${sourceName}] Start Creation`),
        CancelCreation: createAction(`[${sourceName}] Cancel Creation`),
        CreateItem: createAction(`[${sourceName}] Create Item`),
        CreateItemSuccess: createAction(`[${sourceName}] Create Item Success`),
        CreateItemFail: createAction(`[${sourceName}] Create Item Fail`),
        DeleteItem: createAction(
            `[${sourceName}] Delete Item`,
            props<{ id: number }>()
        ),
        DeleteItemSuccess: createAction(`[${sourceName}] Delete Item Success`),
        DeleteItemFail: createAction(`[${sourceName}] Delete Item Fail`),
    };
}

export type RessourceListActions<
    T extends { id?: number },
    TList extends IResourceList<T>
> = {
    Initialize: ActionCreator<
        `[${any}] Initialize`,
        () => TypedAction<`[${any}] Initialize`>
    >;
    InitializeWithFilters: ActionCreator<
        `[${any}] Initialize With Filters`,
        (props: {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        }) => {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        } & TypedAction<`[${any}] Initialize With Filters`>
    >;

    HardResetWithFilters: ActionCreator<
        `[${any}] Hard Reset With Filters`,
        (props: {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        }) => {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        } & TypedAction<`[${any}] Hard Reset With Filters`>
    >;

    LoadListRequest: ActionCreator<
        `[${any}] Load List Request`,
        () => TypedAction<`[${any}] Load List Request`>
    >;

    LoadListSuccess: ActionCreator<
        `[${any}] Load List Success`,
        (props: { response: TList }) => {
            response: TList;
        } & TypedAction<`[${any}] Load List Success`>
    >;

    LoadListFailure: ActionCreator<
        `[${any}] Load List Failure`,
        (props: { error: string }) => {
            error: string;
        } & TypedAction<`[${any}] Load List Failure`>
    >;

    LoadItemRequest: ActionCreator<
        `[${any}] Load Item Request`,
        (props: { id: number }) => {
            id: number;
        } & TypedAction<`[${any}] Load Item Request`>
    >;

    LoadItemRequestSuccess: ActionCreator<
        `[${any}] Load Item Request Success`,
        (props: { response: T }) => {
            response: T;
        } & TypedAction<`[${any}] Load Item Request Success`>
    >;

    LoadItemRequestFailure: ActionCreator<
        `[${any}] Load Item Request Failure`,
        () => TypedAction<`[${any}] Load Item Request Failure`>
    >;

    FilterListRequest: ActionCreator<
        `[${any}] Filter List Request`,
        (props: {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        }) => {
            filters: Array<{
                key: ResourceListFilter;
                value: any;
            }>;
        } & TypedAction<`[${any}] Filter List Request`>
    >;

    SelectItem: ActionCreator<
        `[${any}] Select Item`,
        (props: { id: number }) => {
            id: number;
        } & TypedAction<`[${any}] Select Item`>
    >;

    UnselectItems: ActionCreator<
        `[${any}] Unselect Item`,
        () => TypedAction<`[${any}] Unselect Item`>
    >;

    UpdateSelectedItem: ActionCreator<
        `[${any}] Update Selected Item`,
        () => TypedAction<`[${any}] Update Selected Item`>
    >;

    UpdateSelectedItemSuccess: ActionCreator<
        `[${any}] Update Selected Item Success`,
        () => TypedAction<`[${any}] Update Selected Item Success`>
    >;

    UpdateSelectedItemFail: ActionCreator<
        `[${any}] Update Selected Item Fail`,
        () => TypedAction<`[${any}] Update Selected Item Fail`>
    >;

    StartCreation: ActionCreator<
        `[${any}] Start Creation`,
        () => TypedAction<`[${any}] Start Creation`>
    >;

    CancelCreation: ActionCreator<
        `[${any}] Cancel Creation`,
        () => TypedAction<`[${any}] Cancel Creation`>
    >;

    CreateItem: ActionCreator<
        `[${any}] Create Item`,
        () => TypedAction<`[${any}] Create Item`>
    >;

    CreateItemSuccess: ActionCreator<
        `[${any}] Create Item Success`,
        () => TypedAction<`[${any}] Create Item Success`>
    >;

    CreateItemFail: ActionCreator<
        `[${any}] Create Item Fail`,
        () => TypedAction<`[${any}] Create Item Fail`>
    >;

    DeleteItem: ActionCreator<
        `[${any}] Delete Item`,
        (props: { id: number }) => {
            id: number;
        } & TypedAction<`[${any}] Delete Item`>
    >;

    DeleteItemSuccess: ActionCreator<
        `[${any}] Delete Item Success`,
        () => TypedAction<`[${any}] Delete Item Success`>
    >;

    DeleteItemFail: ActionCreator<
        `[${any}] Delete Item Fail`,
        () => TypedAction<`[${any}] Delete Item Fail`>
    >;
};
