<div
    (click)="open()"
    class="p-6 bg-white rounded-xl shadow-lg hover:bg-warn-200 duration-300 transition-all cursor-pointer"
>
    <div class="grid grid-cols-12 gap-4 items-center">
        <div class="col-span-4 flex flex-col justify-around gap-2 truncate">
            <span class="text-2xl font-bold text-warn truncate">{{
                survey.name
            }}</span>
            <span class="flex items-center gap-2">
                <mat-icon
                    class="text-current"
                    [svgIcon]="
                        survey.isQuiz
                            ? 'heroicons_outline:question-mark-circle'
                            : 'heroicons_outline:chart-pie'
                    "
                ></mat-icon>
                <span>{{
                    (survey.isQuiz ? "surveys.quiz" : "surveys.survey")
                        | transloco
                }}</span>
            </span>
        </div>

        <div class="col-span-3 flex flex-col gap-2 text-center">
            <span class="text-4xl font-bold">{{
                survey.questions?.length ?? 0
            }}</span>
            <span>{{ "surveys.questions-count" | transloco }}</span>
        </div>

        <div class="col-span-3 flex flex-col text-center gap-2">
            <span class="text-4xl font-bold">{{
                survey.participantCount ?? 0
            }}</span>
            <span>{{ "surveys.participants" | transloco }}</span>
        </div>

        <div class="col-span-2 flex flex-col justify-between items-end h-full">
            <bslk-menu
                (click)="$event.stopPropagation()"
                (onAction)="onMenuAction($event)"
                [icon]="'feather:more-vertical'"
                [items]="[
                            {
                                icon: 'heroicons_solid:pencil',
                                action: 'modify',
                                label: 'surveys.modify' | transloco,
                            },
                            {
                                icon: 'heroicons_solid:archive-box',
                                action: 'archive',
                                label: 'surveys.archive' | transloco,
                            },
                    ]"
            ></bslk-menu>

            <div
                [ngSwitch]="survey.state"
                class="flex items-center justify-center"
            >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:play'"
                    [background]="'tertiary'"
                    *ngSwitchCase="ESurveyState.Active"
                    >{{ "surveys.active" | transloco }}</bslk-tag
                >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:eye-slash'"
                    [background]="'warn'"
                    *ngSwitchCase="ESurveyState.NotPublished"
                    >{{ "surveys.not-published" | transloco }}</bslk-tag
                >
                <bslk-tag
                    [size]="'small'"
                    [icon]="'heroicons_outline:calendar'"
                    [background]="'primary'"
                    *ngSwitchCase="ESurveyState.Done"
                    >{{ "surveys.done" | transloco }}</bslk-tag
                >
            </div>
        </div>
    </div>
</div>
