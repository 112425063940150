import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkChartComponent } from '@bslk/components/charts/bslk-chart/bslk-chart.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SurveyQuestionViewModel } from 'app/api';
import { ApexNonAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { SurveyGenericDetailsComponent } from '../generic-details/generic-details.component';

@Component({
    selector: 'survey-single-choice-details',
    templateUrl: './single-choice-details.component.html',
    standalone: true,
    imports: [
        BslkChartComponent,
        TranslocoModule,
        MatIconModule,
        SurveyGenericDetailsComponent,
    ],
})
export class SurveySingleChoiceDetailsComponent implements OnInit {
    @Input() question: SurveyQuestionViewModel;
    @Input() index: number;

    chartOptions: Partial<ApexOptions>;
    series: ApexNonAxisChartSeries;
    labels: string[];

    constructor(private translocoService: TranslocoService) {}

    ngOnInit() {
        this.series = this.question.choices.map(
            (choice) => choice.answeredCount
        );
        this.labels = this.question.choices.map((choice) => choice.choice);

        this.chartOptions = {
            chart: {
                type: 'donut',
                height: 350,
            },
            legend: {
                show: true,
                position: 'right',
                horizontalAlign: 'center',
                width: 200,
                labels: {
                    useSeriesColors: true,
                },
                offsetX: 200,
                fontSize: '16px',
                markers: {
                    width: 24,
                    height: 24,
                    radius: 12,
                },
            },
            dataLabels: {
                enabled: true,
                formatter: (val: number, opts: any) => {
                    const count =
                        this.question.choices[opts.seriesIndex].answeredCount;
                    const percentage = Number.isInteger(val)
                        ? val.toFixed(0)
                        : val.toFixed(1);
                    return `${percentage}% (${count})`;
                },
                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                },
            },
            tooltip: {
                y: {
                    formatter: (val: number) =>
                        `${val} ${this.translocoService.translate(
                            'survey.answers'
                        )}`,
                },
            },
        };
    }
}
