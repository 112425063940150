import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import {
    ESurveyQuestionType,
    SurveyQuestionChoiceViewModel,
    SurveyQuestionViewModel,
    SurveyService,
    SurveyViewModel,
} from 'app/api';
import {
    CustomValidators,
    apiAsyncValidator,
} from 'app/shared/utils/custom-validators.utils';
import { generateGUID } from 'app/shared/utils/guid.utils';
import { isNil } from 'lodash-es';
import { DateTime } from 'luxon';
import { IFormService } from './form-service';
import {
    ESurveyModel,
    SurveyModelBuilderService,
} from './survey-model-builder.service';

@Injectable({
    providedIn: 'root',
})
export class SurveyFormService implements IFormService<SurveyViewModel> {
    private formGroup: FormGroup;

    constructor(
        protected fb: FormBuilder,
        private translocoService: TranslocoService,
        private surveyService: SurveyService,
        private surveyModelBuilderService: SurveyModelBuilderService
    ) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            id: [0],
            dateCreated: [DateTime.now().toISO()],
            dateUpdated: [null],
            dateArchived: [null],
            name: [null, [Validators.required]],
            token: [
                generateGUID(),
                [CustomValidators.validUrlSegmentValidator],
                apiAsyncValidator(
                    this.surveyService,
                    this.surveyService.surveyIdIsTokenAvailableTokenGet,
                    () => {
                        const id = this.formGroup?.controls?.id.value ?? 0;
                        const token = this.formGroup?.controls?.token.value;
                        if (!token) {
                            return null;
                        }
                        return {
                            id,
                            token,
                        };
                    }
                ),
            ],
            isPublished: [true],
            endDate: [null],
            isOpenToUnregistered: [true],
            isQuiz: [false],
            showCorrectAnswers: [true],
            showNumberOfCorrectAnswers: [true],
            canEditAnswers: [false],
            isAnonymous: [false],
            model: [ESurveyModel.NoModel],
            questions: this.fb.array([]),
        });
        return this.formGroup;
    }

    createQuestion(question?: SurveyQuestionViewModel): FormGroup {
        return this.fb.group({
            id: [question?.id ?? 0],
            question: [
                question?.question ?? null,
                [Validators.required, Validators.maxLength(500)],
            ],
            type: [
                question?.type ?? ESurveyQuestionType.SingleChoice,
                Validators.required,
            ],
            language: [
                question?.language ?? this.translocoService.getActiveLang(),
            ],
            isRequired: [question?.isRequired ?? false],
            choices:
                !isNil(question) &&
                question.type !== ESurveyQuestionType.SingleChoice &&
                question.type !== ESurveyQuestionType.MultipleChoice
                    ? null
                    : this.fb.array(
                          question?.choices?.map((choice) =>
                              this.createChoice(choice)
                          ) || [this.createChoice(), this.createChoice()]
                      ),
        });
    }

    createChoice(choice?: SurveyQuestionChoiceViewModel): FormGroup {
        return this.fb.group({
            id: [choice?.id ?? 0],
            choice: [
                choice?.choice,
                [Validators.required, Validators.maxLength(250)],
            ],
            isCorrect: [choice?.isCorrect ?? false],
            language: [
                choice?.language ?? this.translocoService.getActiveLang(),
            ],
        });
    }

    updateForm(entity: SurveyViewModel) {
        this.updateBaseForm(entity);
        this.questionsFormArray.clear();
        entity.questions?.forEach((question) => {
            this.questionsFormArray.push(this.createQuestion(question));
        });
    }

    updateBaseForm(entity: SurveyViewModel) {
        const isQuestionsDirty = this.formGroup.controls.questions.dirty;
        this.formGroup.reset();
        this.formGroup.patchValue(entity);
        if (isQuestionsDirty) {
            this.formGroup.controls.questions.markAsDirty();
        }
    }

    getEntity(): { [key: string]: SurveyViewModel } {
        if (this.formGroup.controls.model.value !== ESurveyModel.NoModel) {
            const questions = this.surveyModelBuilderService.buildSurveyModel(
                this.formGroup.controls.model.value
            );
            questions.forEach((question) => {
                this.questionsFormArray.push(this.createQuestion(question));
            });
        }
        this.formGroup.updateValueAndValidity();
        return {
            surveyViewModel: this.formGroup.value as SurveyViewModel,
        };
    }

    get getFormGroup() {
        return this.formGroup;
    }

    get questionsFormArray(): FormArray {
        return this.formGroup.controls.questions as FormArray;
    }

    get isCoreValid(): boolean {
        return Object.keys(this.formGroup.controls)
            .filter((controlName) => controlName !== 'questions')
            .every((controlName) => this.formGroup.get(controlName)?.valid);
    }

    get dynamicQuestionsErrors(): { [key: number]: string } {
        const errors = {};
        const isQuiz = this.formGroup.controls.isQuiz.value;
        const questionsFormArray = this.questionsFormArray;
        for (let i = 0; i < questionsFormArray.length; i++) {
            const question = questionsFormArray.at(i) as FormGroup;

            if (
                question.controls.type.value ===
                    ESurveyQuestionType.MultipleChoice ||
                question.controls.type.value ===
                    ESurveyQuestionType.SingleChoice
            ) {
                const choicesFormArray = question.controls.choices as FormArray;
                const choices = choicesFormArray.controls;
                if (choices.length < 2) {
                    errors[i] = this.translocoService.translate(
                        'surveys.errors.at-least-two-choices'
                    );
                }
            }

            if (
                question.controls.type.value ===
                    ESurveyQuestionType.SingleChoice &&
                isQuiz
            ) {
                const choicesFormArray = question.controls.choices as FormArray;
                const correctChoices = choicesFormArray.controls.filter(
                    (choice) => choice.get('isCorrect').value
                );
                if (correctChoices.length !== 1) {
                    errors[i] = this.translocoService.translate(
                        'surveys.errors.single-choice'
                    );
                }
            } else if (
                question.controls.type.value ===
                    ESurveyQuestionType.MultipleChoice &&
                isQuiz
            ) {
                const choicesFormArray = question.controls.choices as FormArray;
                const correctChoices = choicesFormArray.controls.filter(
                    (choice) => choice.get('isCorrect').value
                );
                if (correctChoices.length === 0) {
                    errors[i] = this.translocoService.translate(
                        'surveys.errors.at-least-one-correct-choice'
                    );
                }
            }
        }

        return errors;
    }
}
