<div
    (click)="emitClick()"
    [class.h-6]="size === 'small'"
    [class.w-6]="size === 'small'"
    [class.h-10]="size === 'medium'"
    [class.w-10]="size === 'medium'"
    [class.h-12]="size === 'large'"
    [class.w-12]="size === 'large'"
    [class.cursor-pointer]="isButton"
    [matTooltip]="tooltip"
    [matTooltipDisabled]="!tooltip"
    [class]="
        'flex items-center justify-center bg-' +
        color +
        '-100 rounded-full ring-2 ring-white'
    "
>
    <mat-icon
        [class.icon-size-4]="size === 'small'"
        [class.icon-size-6]="size === 'medium'"
        [class.icon-size-8]="size === 'large'"
        [class]="'text-' + color + '-800'"
        [svgIcon]="icon"
        [ngClass]="{ 'hover:animate-wiggle': isButton }"
    >
    </mat-icon>
</div>
