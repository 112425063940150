import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { SurveyResultsViewModel, SurveyViewModel } from 'app/api';

export const SurveyActions = createActionGroup({
    source: 'Survey',
    events: {
        'Load Survey Request By Token': props<{ token: string }>(),
        'Load Survey Request By Id': props<{ id: number }>(),
        'Load Survey Request Success': props<{
            survey: SurveyViewModel;
        }>(),
        'Load Survey Request Failure': props<{
            error: string;
        }>(),

        'Update Survey Request': props<{
            updateOnlyCore: boolean;
            forceReloadQuestions?: boolean;
        }>(),
        'Update Survey Request Success': props<{
            survey: SurveyViewModel;
            updateOnlyCore: boolean;
        }>(),
        'Update Survey Request Failure': props<{
            error: string;
        }>(),

        'Reset Unsaved Changes': emptyProps(),

        'Send Answers Request': emptyProps(),
        'Send Answers Request Success': props<{
            results?: SurveyResultsViewModel;
        }>(),
        'Send Answers Request Failure': props<{
            error: string;
        }>(),
    },
});
