import { AsyncPipe, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BslkCardToggleComponent } from '@bslk/components/form/toggle/card/card-toggle.component';
import { BslkRoundIconComponent } from '@bslk/components/round-icon/round-icon.component';
import { BslkTagComponent } from '@bslk/components/tag/tag.component';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ESurveyState } from 'app/api';
import {
    ECompanyAdminUrl,
    UrlHelpers,
} from 'app/shared/utils/url-helpers.utils';
import { SurveyActions } from 'app/store/survey/survey/survey.action';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { Subject, takeUntil } from 'rxjs';
import { CompanyAdminSurveyConfigurationAnswersComponent } from './answers/answers.component';
import { CompanyAdminSurveyConfigurationQuestionsComponent } from './questions/questions.component';
import { SurveyShareLinkComponentt } from './share-link/share-link.component';

@Component({
    selector: 'company-admin-survey-configuration',
    templateUrl: './survey-configuration.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        NgxSkeletonLoaderModule,
        BslkCardToggleComponent,
        TranslocoModule,
        BslkTagComponent,
        LetDirective,
        NgIf,
        BslkRoundIconComponent,
        NgSwitch,
        NgSwitchCase,
        SurveyShareLinkComponentt,
        CompanyAdminSurveyConfigurationQuestionsComponent,
        CompanyAdminSurveyConfigurationAnswersComponent,
    ],
})
export class CompanyAdminSurveyConfigurationComponent
    implements OnInit, OnDestroy
{
    ESurveyState = ESurveyState;

    unsubscribeAll: Subject<any> = new Subject<any>();

    viewFormControl = new FormControl('questions');

    survey$ = this.store.select(SurveySelectors.selectSurvey);
    isLoading$ = this.store.select(SurveySelectors.selectIsLoading);

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.route.params
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((params) => {
                const id = params['id'];
                if (id) {
                    this.store.dispatch(
                        SurveyActions.loadSurveyRequestById({
                            id,
                        })
                    );
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next(null);
        this.unsubscribeAll.complete();
    }

    goBack() {
        this.router.navigateByUrl(
            UrlHelpers.getCompanyAdminUrl(
                ECompanyAdminUrl.CompanyAdminSurveysPage
            )
        );
    }
}
