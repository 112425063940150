import {
    AbstractControl,
    AsyncValidatorFn,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
} from '@angular/forms';
import { isEmpty, isNil } from 'lodash-es';
import { catchError, map, Observable, of } from 'rxjs';
import { getUserFromLocalStorage } from './local-storage.utils';
import { extractTextFromHtml } from './text.utils';
function passwordValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value: string = control.value || '';
        const numberCharacters = /[0-9]+/g;
        const specialCharacters = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (!numberCharacters.test(value)) {
            return { noNumber: true };
        }

        if (!specialCharacters.test(value)) {
            return { noSpecialCharacter: true };
        }

        if (value.length < 8) {
            return { minLength: true };
        }

        return null;
    };
}

function codeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        const isValid = /^[A-Z0-9]{4}-[A-Z0-9]{4}$/.test(value);
        return isValid
            ? null
            : { sixDigitNumber: { value: 'Not a valid format' } };
    };
}

function youtubeVideoIdValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (!control.value || control.value.trim() === '') {
            return null;
        }
        const valid = /^[a-zA-Z0-9_-]{11}$/.test(control.value);
        return valid
            ? null
            : { invalidYoutubeVideoId: { value: control.value } };
    };
}

function htmlTextLengthValidator(
    minLength: number,
    maxLength: number
): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        if (control.value == null || control.value === '') return null;

        const textContent = extractTextFromHtml(control.value);

        if (textContent.length > maxLength) {
            return { textTooLong: { value: control.value } };
        }

        if (textContent.length < minLength) {
            return { textTooShort: { value: control.value } };
        }

        return null;
    };
}

function passwordMatch(group: AbstractControl): ValidationErrors | null {
    const pass = group.get('password').value;
    const confirmPass = group.get('confirmPassword').value;
    return pass === confirmPass ? null : { notSame: true };
}

function startAndEndDateValidator(
    group: AbstractControl
): ValidationErrors | null {
    const startDate = group.get('startDate').value;
    const endDate = group.get('endDate').value;

    if (!isEmpty(startDate) && isEmpty(endDate)) {
        return { missingEndDate: true };
    }
    if (startDate && endDate && startDate >= endDate) {
        return { dateMismatch: true };
    }
    return null;
}

function minArrayLengthValidator(min: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!control.value || control.value.length < min) {
            return { minArrayLength: true };
        }
        return null;
    };
}

function maxArrayLengthValidator(max: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
        if (!control.value || control.value.length > max) {
            return { manArrayLength: true };
        }
        return null;
    };
}

export function timeValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const value = control.value;
        const isValid = /^([01]?[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/.test(
            value
        );
        return isValid ? null : { invalidTime: { value } };
    };
}

export function validateManagerEmail(): ValidatorFn {
    return (formGroup: FormGroup) => {
        const user = getUserFromLocalStorage();
        const allowedDomains = user?.company?.domain?.split(';');

        // Case when updating from admin panel
        if (isNil(allowedDomains)) {
            return null;
        }

        const emailValue = formGroup.get('email')?.value;
        const managerEmailControl = formGroup.get('managerEmail');
        const managerEmailValue = formGroup.get('managerEmail').value;

        if (!emailValue || !managerEmailValue) {
            return null;
        }

        const managerEmailDomain = managerEmailValue.substring(
            managerEmailValue.lastIndexOf('@') + 1
        );

        if (emailValue === managerEmailValue) {
            managerEmailControl.setErrors({ sameAsOwnEmail: true });
        } else if (
            !allowedDomains ||
            !allowedDomains.includes(managerEmailDomain)
        ) {
            managerEmailControl.setErrors({ differentDomain: true });
        } else {
            managerEmailControl.setErrors(null);
        }

        return null;
    };
}

export function validUrlSegmentValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        const urlSegmentPattern = /^[a-zA-Z0-9-]+$/;
        const forbidden = !urlSegmentPattern.test(control.value);
        return forbidden
            ? { invalidUrlSegment: { value: control.value } }
            : null;
    };
}

export function maxLengthIfNotEmpty(max: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!isEmpty(value) && value.length > max) {
            return {
                maxLength: { requiredLength: max, actualLength: value.length },
            };
        }
        return null;
    };
}

import { debounceTime, switchMap } from 'rxjs/operators';

export function apiAsyncValidator(
    apiService: any,
    apiCall: (params: any) => Observable<boolean>,
    parameters: () => any,
    debounceTimeMs = 300
): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
        if (!control.value) {
            return of(null);
        }

        return of(control.value).pipe(
            debounceTime(debounceTimeMs),
            switchMap(() => {
                const params = parameters();
                if (!params) {
                    return of({ invalidParameters: true });
                }

                const boundApiCall = apiCall.bind(apiService);
                const apiObservable = boundApiCall(
                    params
                ) as Observable<boolean>;

                return apiObservable.pipe(
                    map((isValid: boolean): ValidationErrors | null => {
                        return isValid ? null : { asyncInvalid: true };
                    }),
                    catchError((error): Observable<ValidationErrors> => {
                        console.error('API Error:', error);
                        return of({ apiError: true });
                    })
                );
            })
        );
    };
}

export const CustomValidators = {
    password: passwordValidator(),
    code: codeValidator(),
    youtubeVideoIdValidator: youtubeVideoIdValidator(),
    htmlTextLengthValidator: (minLength: number, maxLength: number) =>
        htmlTextLengthValidator(minLength, maxLength),
    passwordMatch: (group: AbstractControl) => passwordMatch(group),
    startAndEndDateValidator: (group: AbstractControl) =>
        startAndEndDateValidator(group),
    minArrayLengthValidator: (min: number) => minArrayLengthValidator(min),
    maxArrayLengthValidator: (max: number) => maxArrayLengthValidator(max),
    timeValidator: timeValidator(),
    validateManagerEmail: validateManagerEmail(),
    validUrlSegmentValidator: validUrlSegmentValidator(),
    maxLengthIfNotEmpty: (max: number) => maxLengthIfNotEmpty(max),
};
