<div
    (click)="buttonClick()"
    [class.cursor-pointer]="!isDisabled && !isLoading"
    [class.grayscale]="isDisabled"
    [ngClass]="{
        'px-4 py-2': size === 'medium',
        'px-2 py-1 text-sm': size === 'small',
        'hover:bg-warn border-warn': color === 'warn',
        'hover:bg-primary border-primary': color === 'primary',
    }"
    [matTooltip]="isDisabledTooltip"
    [matTooltipDisabled]="!isDisabled"
    class="bg-white text-black group hover:text-white duration-300 transition-all border-2 font-semibold relative rounded-lg"
>
    <div class="flex items-center gap-2">
        <mat-icon
            class="text-current"
            [svgIcon]="icon"
            *ngIf="icon && !isLoading"
        ></mat-icon>
        <mat-progress-spinner
            [diameter]="24"
            [mode]="'indeterminate'"
            *ngIf="isLoading"
        ></mat-progress-spinner>
        <span class="whitespace-nowrap"><ng-content></ng-content></span>
    </div>

    <div
        *ngIf="number"
        class="absolute -right-2 -top-1 h-4 w-4 bg-primary rounded-full flex text-center items-center justify-center"
    >
        <span class="text-xs">{{ number }}</span>
    </div>
</div>
