import { createReducer, on } from '@ngrx/store';
import { clone } from 'lodash-es';
import { SurveyActions } from './survey.action';
import { SurveyState } from './survey.state';

export const surveyInitialState: SurveyState = {
    isLoading: false,
    isLoadingAnswering: false,
    hasSentAnswers: false,
};

export const surveyReducer = createReducer(
    surveyInitialState,

    on(SurveyActions.loadSurveyRequestById, (state) => ({
        ...state,

        isLoading: true,
    })),

    on(SurveyActions.loadSurveyRequestByToken, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(SurveyActions.loadSurveyRequestSuccess, (state, { survey }) => ({
        ...state,
        isLoading: false,
        survey,
    })),

    on(SurveyActions.loadSurveyRequestFailure, (state) => ({
        ...state,
        isLoading: false,
    })),

    on(SurveyActions.updateSurveyRequest, (state) => ({
        ...state,
        isLoading: true,
    })),

    on(
        SurveyActions.updateSurveyRequestSuccess,
        (state, { survey, updateOnlyCore }) => {
            const oldQuestions = state.survey.questions;
            const newSurvey = clone(survey);
            newSurvey.questions = updateOnlyCore
                ? oldQuestions
                : survey.questions;
            return {
                ...state,
                isLoading: false,
                survey: newSurvey,
            };
        }
    ),

    on(SurveyActions.updateSurveyRequestFailure, (state) => ({
        ...state,
        isLoading: false,
    })),

    on(SurveyActions.sendAnswersRequest, (state) => ({
        ...state,
        isLoadingAnswering: true,
    })),

    on(SurveyActions.sendAnswersRequestSuccess, (state, { results }) => ({
        ...state,
        isLoadingAnswering: false,
        hasSentAnswers: true,
        results,
    })),

    on(SurveyActions.sendAnswersRequestFailure, (state) => ({
        ...state,
        isLoadingAnswering: false,
    }))
);
