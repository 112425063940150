<survey-generic-details [question]="question" [index]="index">
    <div class="flex flex-col gap-2 pt-4">
        <div
            [matTooltip]="answer.email"
            [matTooltipDisabled]="isAnonymous$ | async"
            *ngFor="let answer of answers"
            class="p-2 bg-tertiary-100 border-l-4 border-tertiary"
        >
            {{ answer.openAnswer }}
        </div>
    </div>
</survey-generic-details>
