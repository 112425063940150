import { SurveyViewModel } from 'app/api';
import { AppState } from '../..';
import {
    RessourceListSelectors,
    createRessourceListSelectors,
} from '../../shared/ressource-list/ressource-list-selectors.creator';

const selectState = (state: AppState) => state.surveyList;

type SurveyListSelectors = RessourceListSelectors<SurveyViewModel>;

export const SurveyListSelectors: SurveyListSelectors = {
    ...createRessourceListSelectors('surveyList'),
};
