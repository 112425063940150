import { createSelector } from '@ngrx/store';
import { ESurveyState } from 'app/api';
import { EExternalUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { AppState } from 'app/store';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { environment } from 'environments/environment';
import { isNil } from 'lodash-es';

const selectState = (state: AppState) => state.survey;

const selectIsLoading = createSelector(selectState, (state) => state.isLoading);

const selectHasSentAnswers = createSelector(
    selectState,
    (state) => state.hasSentAnswers
);

const selectShowThankYou = createSelector(
    selectState,
    (state) => state.hasSentAnswers && !state.results
);

const selectResults = createSelector(selectState, (state) => state.results);

const selectHasResults = createSelector(
    selectState,
    (state) => !isNil(state.results)
);

const selectIsLoadingAnswering = createSelector(
    selectState,
    (state) => state.isLoadingAnswering
);

const selectSurvey = createSelector(selectState, (state) => state.survey);

const selectIsNotPublished = createSelector(
    selectSurvey,
    (survey) => survey?.state === ESurveyState.NotPublished
);

const selectIsDone = createSelector(
    selectSurvey,
    (survey) => survey?.state === ESurveyState.Done
);

const selectIsActive = createSelector(
    selectSurvey,
    (survey) => survey?.state === ESurveyState.Active
);

const selectIsAnonymous = createSelector(
    selectSurvey,
    (survey) => survey.isAnonymous
);

const selectSurveyIsNotPublished = createSelector(
    selectSurvey,
    (survey) => survey?.state === ESurveyState.NotPublished
);
const selectSurveyIsDone = createSelector(
    selectSurvey,
    (survey) => survey?.state === ESurveyState.Done
);

const selectSurveyUrl = createSelector(selectState, (state) => {
    return (
        environment.redirectUri +
        UrlHelpers.getExternalUrl(EExternalUrl.Survey, {
            id: state.survey?.token.toString(),
        })
    );
});

const selectMustSpecifyEmail = createSelector(
    selectSurvey,
    selectHasResults,
    UserSelectors.selectIsLoggedIn,
    (survey, isLoggedIn, hasResults) =>
        survey.isOpenToUnregistered &&
        !survey?.isAnonymous &&
        !isLoggedIn &&
        !hasResults
);

export const SurveySelectors = {
    selectIsLoading,
    selectIsLoadingAnswering,
    selectSurvey,
    selectSurveyUrl,
    selectSurveyIsNotPublished,
    selectHasSentAnswers,
    selectSurveyIsDone,
    selectMustSpecifyEmail,
    selectHasResults,
    selectResults,
    selectIsAnonymous,
    selectIsNotPublished,
    selectShowThankYou,
    selectIsDone,
    selectIsActive,
};
