<div
    *ngrxLet="survey$ as survey"
    class="bg-white rounded-lg shadow-lg p-4 flex flex-col gap-2"
>
    <div class="flex justify-between items-center">
        <div class="flex items-center gap-2">
            <mat-icon
                class="text-current"
                [svgIcon]="'heroicons_outline:users'"
            ></mat-icon>
            <span class="text-xl">{{ "survey.participants" | transloco }}</span>
        </div>
    </div>

    <div
        *ngIf="survey.participants.length === 0"
        class="grow flex flex-col justify-center gap-5 text-lg items-center mt-10"
    >
        <mat-icon
            [svgIcon]="'feather:frown'"
            class="text-current icon-size-12"
        ></mat-icon>
        {{ "survey.no-participant" | transloco }}
    </div>

    <ng-container *ngIf="!survey.isQuiz && survey.participants.length > 0">
        <div class="flex flex-col gap-2">
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.email" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.date" | transloco }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="border-x">
                        <tr
                            *ngFor="let participant of survey.participants"
                            class="bg-white border-b"
                        >
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.email }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.date | date : "short" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="survey.isQuiz && survey.quizRankings.length > 0">
        <div class="flex flex-col gap-2">
            <div class="relative overflow-x-scroll">
                <table class="w-full text-left mt-1">
                    <thead
                        class="bg-warn shadow-outside text-lg shadow-warn-50 text-white"
                    >
                        <tr>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.rank" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.email" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.score" | transloco }}
                            </th>
                            <th scope="col" class="px-6 py-3">
                                {{ "survey.date" | transloco }}
                            </th>
                        </tr>
                    </thead>
                    <tbody class="border-x">
                        <tr
                            *ngFor="let participant of survey.quizRankings"
                            class="bg-white border-b"
                        >
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.rank }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.email }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.score }}
                            </td>
                            <td
                                class="px-2 py-2 lg:px-4 lg:py-4 xxl:px-6 xxl:py-4"
                            >
                                {{ participant.date | date : "short" }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </ng-container>
</div>
