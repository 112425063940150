import { Component, Input, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { BslkChartComponent } from '@bslk/components/charts/bslk-chart/bslk-chart.component';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { SurveyQuestionViewModel } from 'app/api';
import { ApexAxisChartSeries, ApexOptions } from 'ng-apexcharts';
import { SurveyGenericDetailsComponent } from '../generic-details/generic-details.component';

@Component({
    selector: 'survey-rating-details',
    templateUrl: './rating-details.component.html',
    standalone: true,
    imports: [
        BslkChartComponent,
        TranslocoModule,
        MatIconModule,
        SurveyGenericDetailsComponent,
    ],
})
export class SurveyRatingDetailsComponent implements OnInit {
    @Input() question: SurveyQuestionViewModel;
    @Input() index: number;

    chartOptions: Partial<ApexOptions>;
    series: ApexAxisChartSeries | number[];

    constructor(private translocoService: TranslocoService) {}

    ngOnInit() {
        const percentages = Object.values(this.question.ratingsPercentage);
        const counts = this.question.ratingsCount;

        this.series = [
            {
                name: this.translocoService.translate('survey.vote-count'),
                data: percentages,
            },
        ];

        this.chartOptions = {
            chart: {
                type: 'bar',
                height: 300,
            },
            plotOptions: {
                bar: {
                    distributed: true,
                },
            },
            colors: ['#8490c8'],
            xaxis: {
                categories: [1, 2, 3, 4, 5],
                labels: {
                    show: true,
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                    },
                },
            },
            yaxis: {
                show: false,
            },
            dataLabels: {
                enabled: true,
                formatter: (val: number, opts: any) => {
                    const rating =
                        opts.w.config.xaxis.categories[opts.dataPointIndex];
                    const count = counts[rating] || 0;

                    const percentage = Number.isInteger(val)
                        ? val.toString()
                        : val.toFixed(1);

                    return `${percentage}% (${count})`;
                },

                style: {
                    fontSize: '16px',
                    fontWeight: 'bold',
                },
            },
            grid: {
                show: false,
            },
        };
    }
}
