<ng-container *ngIf="!(isLoading$ | async); else loading">
    <div
        *ngrxLet="campaign$ as campaign"
        class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-8"
    >
        <div
            class="flex flex-col lg:flex-row items-start justify-between gap-6"
        >
            <div class="flex flex-col gap-1 truncate">
                <div class="flex items-center gap-3 p-1">
                    <bslk-round-icon
                        class="cursor-pointer"
                        [icon]="'heroicons_solid:arrow-uturn-left'"
                        [color]="'warn'"
                        [matTooltip]="'shared.navigation.go-back' | transloco"
                        (click)="goBack()"
                    >
                    </bslk-round-icon>
                    <div class="flex items-center gap-2">
                        <span class="text-3xl font-bold truncate">{{
                            campaign.name
                        }}</span>
                    </div>
                </div>

                <span class="text-lg">{{
                    "donationcampaign.manage-track-share-campaign" | transloco
                }}</span>
                <div
                    [ngSwitch]="campaign.state"
                    class="flex gap-3 items-center mt-2"
                >
                    <bslk-tag
                        [icon]="'heroicons_outline:play'"
                        [background]="'tertiary'"
                        *ngSwitchCase="EDonationCampaignState.Active"
                        >{{ "donationcampaign.active" | transloco }}</bslk-tag
                    >
                    <bslk-tag
                        [icon]="'heroicons_outline:credit-card'"
                        [background]="'warn'"
                        *ngSwitchCase="EDonationCampaignState.WaitingForPayment"
                        >{{
                            "donationcampaign.waiting-for-payment" | transloco
                        }}</bslk-tag
                    >
                    <bslk-tag
                        [icon]="'heroicons_outline:calendar'"
                        [background]="'primary'"
                        *ngSwitchCase="EDonationCampaignState.Expired"
                        >{{ "donationcampaign.expired" | transloco }}</bslk-tag
                    >
                    <bslk-tag
                        [icon]="'feather:star'"
                        [background]="'quaternary'"
                        *ngSwitchCase="EDonationCampaignState.Completed"
                        >{{
                            "donationcampaign.completed" | transloco
                        }}</bslk-tag
                    >
                    <div class="flex">
                        <bslk-tag-button
                            *ngIf="
                                campaign.state ===
                                EDonationCampaignState.WaitingForPayment
                            "
                            (click)="downloadFundraisingAppeal(campaign.id)"
                            [icon]="'heroicons_outline:arrow-right-circle'"
                            >{{
                                "donationcampaign.pay-fundraising-appeal"
                                    | transloco
                            }}</bslk-tag-button
                        >
                        <bslk-tag-button
                            *ngIf="
                                campaign.state !==
                                EDonationCampaignState.WaitingForPayment
                            "
                            (click)="downloadTaxReceipt(campaign.id)"
                            [icon]="'heroicons_outline:document-text'"
                            >{{
                                "donationcampaign.download-tax-receipt"
                                    | transloco
                            }}</bslk-tag-button
                        >
                    </div>
                </div>
                <div class="flex gap-2 mt-1">
                    <bslk-tag-button
                        (click)="openPreview(campaign)"
                        [icon]="'heroicons_outline:computer-desktop'"
                        >{{
                            "donationcampaign.preview" | transloco
                        }}</bslk-tag-button
                    >
                    <bslk-tag-button
                        (click)="modifyCampaign()"
                        [icon]="'heroicons_outline:pencil'"
                        >{{
                            "donationcampaign.modify" | transloco
                        }}</bslk-tag-button
                    >
                    <bslk-tag-button
                        (click)="customizeCampaign(campaign)"
                        [icon]="'heroicons_outline:photo'"
                        >{{
                            "donationcampaign.customize" | transloco
                        }}</bslk-tag-button
                    >
                </div>
            </div>
            <donation-campaign-share-link
                class="w-full lg:w-auto"
            ></donation-campaign-share-link>
        </div>
        <donation-campaign-whitelisted-codes
            *ngIf="displayWhitelistedCodes$ | async"
        ></donation-campaign-whitelisted-codes>
        <donation-campaign-requests-list></donation-campaign-requests-list>
        <donation-campaign-requests-repartition></donation-campaign-requests-repartition>
    </div>
</ng-container>

<donation-campaign-legal-documents></donation-campaign-legal-documents>

<ng-template #loading>
    <div class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-8">
        <div class="flex justify-between">
            <div class="flex flex-col gap-2">
                <div class="h-20"></div>
                <div class="flex gap-4">
                    <div class="overflow-hidden rounded-xl h-10 w-40">
                        <ngx-skeleton-loader
                            [theme]="{ height: '100%' }"
                            class="w-full h-full"
                        ></ngx-skeleton-loader>
                    </div>
                    <div class="overflow-hidden rounded-xl h-10 w-40">
                        <ngx-skeleton-loader
                            [theme]="{ height: '100%' }"
                            class="w-full h-full"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <div
                class="min-w-120 xxl:min-w-160 overflow-hidden rounded-2xl shadow-lg"
            >
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <div class="w-full h-64 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
        <div class="w-full h-64 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
