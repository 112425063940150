<ng-container *ngIf="!(isLoading$ | async); else loading">
    <div
        *ngrxLet="survey$ as survey"
        class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-8"
    >
        <div
            class="flex flex-col lg:flex-row items-start justify-between gap-6"
        >
            <div class="flex flex-col gap-1 truncate">
                <div class="flex items-center gap-3 p-1 truncate">
                    <bslk-round-icon
                        class="cursor-pointer"
                        [icon]="'heroicons_solid:arrow-uturn-left'"
                        [color]="'warn'"
                        [matTooltip]="'shared.navigation.go-back' | transloco"
                        (click)="goBack()"
                    >
                    </bslk-round-icon>
                    <div class="flex items-center gap-2 truncate">
                        <span class="text-3xl font-bold truncate">{{
                            survey.name
                        }}</span>
                    </div>
                </div>

                <span class="text-lg">{{
                    "survey.manage-survey" | transloco
                }}</span>
                <div
                    [ngSwitch]="survey.state"
                    class="flex gap-3 items-center mt-2"
                >
                    <bslk-tag
                        [size]="'small'"
                        [icon]="'heroicons_outline:play'"
                        [background]="'tertiary'"
                        *ngSwitchCase="ESurveyState.Active"
                        >{{ "surveys.active" | transloco }}</bslk-tag
                    >
                    <bslk-tag
                        [size]="'small'"
                        [icon]="'heroicons_outline:eye-slash'"
                        [background]="'warn'"
                        *ngSwitchCase="ESurveyState.NotPublished"
                        >{{ "surveys.not-published" | transloco }}</bslk-tag
                    >
                    <bslk-tag
                        [size]="'small'"
                        [icon]="'heroicons_outline:calendar'"
                        [background]="'primary'"
                        *ngSwitchCase="ESurveyState.Done"
                        >{{ "surveys.done" | transloco }}</bslk-tag
                    >
                </div>
            </div>

            <survey-share-link class="w-full lg:w-auto"></survey-share-link>
        </div>

        <div class="bg-white rounded-lg shadow-lg p-4 flex flex-col gap-2">
            <span class="font-semibold">{{
                "survey.choose-view" | transloco
            }}</span>
            <bslk-card-toggle
                [color]="'warn'"
                [choices]="[
                    {
                        text: 'survey.questions-title' | transloco,
                        description: 'survey.questions-description' | transloco,
                        icon: 'heroicons_outline:question-mark-circle',
                        value: 'questions'
                    },
                    {
                        text: 'survey.answers-title' | transloco,
                        description: 'survey.answers-description' | transloco,
                        icon: 'heroicons_outline:chat-bubble-bottom-center-text',
                        value: 'answers'
                    }
                ]"
                [control]="viewFormControl"
            ></bslk-card-toggle>
        </div>

        <company-admin-survey-configuration-questions
            *ngIf="viewFormControl.value === 'questions'"
        ></company-admin-survey-configuration-questions>
        <company-admin-survey-configuration-answers
            *ngIf="viewFormControl.value === 'answers'"
        >
        </company-admin-survey-configuration-answers>
    </div>
</ng-container>

<ng-template #loading>
    <div class="p-4 md:p-6 xxl:p-8 w-full flex flex-col gap-8">
        <div class="flex justify-between">
            <div class="flex flex-col gap-2">
                <div class="h-20"></div>
                <div class="flex gap-4">
                    <div class="overflow-hidden rounded-xl h-10 w-40">
                        <ngx-skeleton-loader
                            [theme]="{ height: '100%' }"
                            class="w-full h-full"
                        ></ngx-skeleton-loader>
                    </div>
                    <div class="overflow-hidden rounded-xl h-10 w-40">
                        <ngx-skeleton-loader
                            [theme]="{ height: '100%' }"
                            class="w-full h-full"
                        ></ngx-skeleton-loader>
                    </div>
                </div>
            </div>
            <div
                class="min-w-120 xxl:min-w-160 overflow-hidden rounded-2xl shadow-lg"
            >
                <ngx-skeleton-loader
                    [theme]="{ height: '100%' }"
                    class="w-full h-full"
                ></ngx-skeleton-loader>
            </div>
        </div>
        <div class="w-full h-64 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
        <div class="w-full h-64 overflow-hidden rounded-2xl shadow-lg">
            <ngx-skeleton-loader
                [theme]="{ height: '100%' }"
                class="w-full h-full"
            ></ngx-skeleton-loader>
        </div>
    </div>
</ng-template>
