<div
    [ngClass]="{
        'bg-primary': color === 'primary',
        'bg-warn': color === 'warn',
        'bg-tertiary': color === 'tertiary',
  }"
    class="rounded-2xl shadow-md h-full min-h-40 bg-opacity-60 p-4 flex flex-col gap-2 text-center relative"
>
    <span
        class="font-extrabold text-4xl truncate flex items-center justify-center"
    >
        <ng-container *ngIf="!metric?.isRange">
            <bslk-animated-number
                *ngIf="metric?.number && showAnimation"
                [endValue]="metric?.number"
            ></bslk-animated-number>
            <span *ngIf="!showAnimation">{{
                metric?.number | shortenNumber
            }}</span>
            <span *ngIf="metric?.isMoreThan">+</span>
        </ng-container>

        <ng-container *ngIf="metric?.isRange">
            <bslk-animated-number
                *ngIf="metric?.fromNumber && showAnimation"
                [endValue]="metric?.fromNumber"
            ></bslk-animated-number>
            <span *ngIf="!showAnimation">{{
                metric?.fromNumber | shortenNumber
            }}</span>
            <span class="mx-2">-</span>
            <bslk-animated-number
                *ngIf="metric?.toNumber && showAnimation"
                [endValue]="metric?.toNumber"
            ></bslk-animated-number>
            <span *ngIf="!showAnimation">{{
                metric?.toNumber | shortenNumber
            }}</span>
        </ng-container>

        &nbsp;<span *ngIf="metric?.unit !== EUnit.None">{{
            "bslkbusiness.metric.units." + metric?.unit | transloco
        }}</span></span
    >

    <div class="flex flex-col grow items-center justify-center font-semibold">
        <span>{{ metric.description }}</span>
    </div>
</div>
