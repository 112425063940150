import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
    ESurveyQuestionType,
    SurveyAnswersWrapperViewModel,
    SurveyUserAnswerViewModel,
    SurveyViewModel,
} from 'app/api';
import { IFormService } from './form-service';

@Injectable({
    providedIn: 'root',
})
export class SurveyAnswersFormService
    implements IFormService<SurveyAnswersWrapperViewModel>
{
    private formGroup: FormGroup;

    constructor(private fb: FormBuilder) {
        this.initForm();
    }

    initForm(): FormGroup {
        this.formGroup = this.fb.group({
            surveyId: [null, Validators.required],
            email: [null, [Validators.email]],
            answers: this.fb.array([]),
        });
        return this.formGroup;
    }

    createAnswer(answer?: SurveyUserAnswerViewModel): FormGroup {
        return this.fb.group({
            id: [answer?.id ?? 0],
            openAnswer: [answer?.openAnswer ?? null],
            surveyQuestionId: [
                answer?.surveyQuestionId ?? null,
                Validators.required,
            ],
            rating: [answer?.rating ?? null],
            surveyQuestionChoiceIds: [answer?.surveyQuestionChoiceIds ?? []],
            userId: [answer?.userId ?? null],
        });
    }

    updateForm(entity: SurveyAnswersWrapperViewModel) {
        this.formGroup.reset();
        this.formGroup.patchValue({
            surveyId: entity.surveyId,
            email: entity.email,
        });
        const answersControl = this.formGroup.controls.answers as FormArray;
        answersControl.clear();
        entity.answers?.forEach((answer) => {
            answersControl.push(this.createAnswer(answer));
        });
    }

    updateFormFromSurvey(survey: SurveyViewModel, isLoggedIn: boolean) {
        this.formGroup.reset();
        this.formGroup.patchValue({ surveyId: survey.id });

        if (!isLoggedIn) {
            this.formGroup.controls.email.setValidators([
                Validators.required,
                Validators.email,
            ]);
        }

        const answersControl = this.formGroup.controls.answers as FormArray;
        survey.questions.forEach((question, _) => {
            const answerGroup = this.createAnswer();
            answersControl.push(answerGroup);
            answerGroup.patchValue({
                surveyQuestionId: question.id,
            });
            if (
                question.type === ESurveyQuestionType.ShortAnswer ||
                question.type === ESurveyQuestionType.Paragraph
            ) {
                const openAnswerControl = answerGroup.controls.openAnswer;
                openAnswerControl?.clearValidators();

                if (question.isRequired) {
                    openAnswerControl?.setValidators([
                        Validators.required,
                        Validators.maxLength(
                            question.type === ESurveyQuestionType.ShortAnswer
                                ? 150
                                : 1000
                        ),
                    ]);
                }
                openAnswerControl?.updateValueAndValidity();
            } else if (
                question.type === ESurveyQuestionType.SingleChoice ||
                question.type === ESurveyQuestionType.MultipleChoice
            ) {
                const choiceIdControl =
                    answerGroup.controls.surveyQuestionChoiceIds;
                choiceIdControl?.clearValidators();

                if (question.isRequired || survey.isQuiz) {
                    choiceIdControl?.setValidators(Validators.required);
                }
                choiceIdControl?.updateValueAndValidity();
            } else if (question.type === ESurveyQuestionType.Rating) {
                const ratingControl = answerGroup.controls.rating;
                ratingControl?.clearValidators();

                if (question.isRequired) {
                    ratingControl?.setValidators(Validators.required);
                }
                ratingControl?.updateValueAndValidity();
            }
        });
    }

    getEntity(): { [key: string]: SurveyAnswersWrapperViewModel } {
        this.formGroup.updateValueAndValidity();
        return {
            surveyAnswersWrapperViewModel: this.formGroup
                .value as SurveyAnswersWrapperViewModel,
        };
    }

    get getFormGroup() {
        return this.formGroup;
    }

    addAnswer(answer?: SurveyUserAnswerViewModel) {
        const answersControl = this.formGroup.controls.answers as FormArray;
        answersControl.push(this.createAnswer(answer));
    }

    removeAnswer(index: number) {
        const answersControl = this.formGroup.controls.answers as FormArray;
        answersControl.removeAt(index);
    }
}
