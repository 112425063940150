import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
    MAT_DIALOG_DATA,
    MatDialogModule,
    MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { BslkClassicButtonComponent } from '@bslk/components/button/classic/classic-button.component';
import { BslkClassicToggleComponent } from '@bslk/components/form/toggle/classic/classic-toggle.component';
import { BslkInfoLabelComponent } from '@bslk/components/info-label/info-label.component';
import { FuseAlertComponent } from '@fuse/components/alert';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ESurveyQuestionType, SurveyQuestionViewModel } from 'app/api';
import { BaseDialogComponent } from 'app/shared/components/dialog/base/base-dialog.component';
import { SurveyFormService } from 'app/shared/services/form/survey-form.service';
import { ESurveyModel } from 'app/shared/services/form/survey-model-builder.service';
import { fromTodayDatesFilter } from 'app/shared/utils/date-helpers.utils';
import { EExternalUrl, UrlHelpers } from 'app/shared/utils/url-helpers.utils';
import { SurveyListActions } from 'app/store/survey/survey-list/survey-list.actions';
import { SurveyListSelectors } from 'app/store/survey/survey-list/survey-list.selectors';
import { SurveyActions } from 'app/store/survey/survey/survey.action';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { environment } from 'environments/environment';
import { DateTime } from 'luxon';

export type CompanyAdminSurveySettingsInput = {
    isEditing: boolean;
    isFromEntityPage: boolean;
    editingId?: number;
};

@Component({
    selector: 'company-admin-survey-settings-dialog',
    templateUrl: './settings-dialog.component.html',
    standalone: true,
    imports: [
        AsyncPipe,
        BaseDialogComponent,
        BaseDialogComponent,
        BslkClassicButtonComponent,
        BslkClassicToggleComponent,
        BslkInfoLabelComponent,
        FuseAlertComponent,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatOptionModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        TranslocoModule,
    ],
})
export class CompanyAdminSurveySettingsDialogComponent implements OnInit {
    ESurveyModel = ESurveyModel;

    switchedToQuiz = false;

    isLoading$ = this.data.isFromEntityPage
        ? this.store.select(SurveySelectors.selectIsLoading)
        : this.store.select(SurveyListSelectors.selectIsLoading);

    constructor(
        private dialogRef: MatDialogRef<CompanyAdminSurveySettingsDialogComponent>,
        private formService: SurveyFormService,
        private store: Store,
        @Inject(MAT_DIALOG_DATA)
        public data: CompanyAdminSurveySettingsInput
    ) {}

    get formGroup() {
        return this.formService.getFormGroup;
    }

    get isCoreFormValid() {
        return this.formService.isCoreValid;
    }

    get urlPrefix() {
        return (
            environment.redirectUri +
            UrlHelpers.getExternalUrl(EExternalUrl.Survey, { id: '' })
        );
    }

    ngOnInit() {
        if (!this.data.isFromEntityPage) {
            if (this.data.isEditing && this.data.editingId) {
                this.store.dispatch(
                    SurveyListActions.SelectItem({ id: this.data.editingId })
                );
            } else {
                this.store.dispatch(SurveyListActions.StartCreation());
            }
        }
    }

    close() {
        if (this.data.isFromEntityPage) {
            this.store.dispatch(SurveyActions.resetUnsavedChanges());
        }
        this.dialogRef.close();
    }

    submit() {
        if (this.isCoreFormValid) {
            if (!this.data.isFromEntityPage) {
                if (this.data.isEditing) {
                    this.store.dispatch(SurveyListActions.UpdateSelectedItem());
                } else {
                    this.store.dispatch(SurveyListActions.CreateItem());
                }
            } else {
                this.store.dispatch(
                    SurveyActions.updateSurveyRequest({
                        updateOnlyCore: true,
                        forceReloadQuestions: this.switchedToQuiz,
                    })
                );
            }
        }
    }

    getDatesFilter(date: DateTime) {
        return fromTodayDatesFilter(date);
    }

    onIsQuizChange(isQuiz: boolean) {
        if (
            isQuiz &&
            this.formGroup.controls.questions.value.some(
                (q: SurveyQuestionViewModel) =>
                    q.type !== ESurveyQuestionType.SingleChoice &&
                    q.type !== ESurveyQuestionType.MultipleChoice
            )
        ) {
            this.switchedToQuiz = true;
        }
    }
}
