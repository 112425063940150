import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SurveyAnonymousService, SurveyService } from 'app/api';
import { CompanyAdminSurveySettingsDialogService } from 'app/modules/company-admin/surveys/settings-dialog/settings-dialog.service';
import { SurveyAnswersFormService } from 'app/shared/services/form/survey-answers-form.service';
import { SurveyFormService } from 'app/shared/services/form/survey-form.service';
import { AlertActions } from 'app/store/alert/alert.actions';
import { UserSelectors } from 'app/store/user/user/user.selectors';
import { cloneDeep } from 'lodash-es';
import { catchError, exhaustMap, map, mergeMap, of, tap } from 'rxjs';
import { SurveyActions } from './survey.action';
import { SurveySelectors } from './survey.selectors';

@Injectable()
export class SurveyEffects {
    loadById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.loadSurveyRequestById),
            exhaustMap(({ id }) => {
                return this.surveyService.surveyIdGet({ id }).pipe(
                    map((survey) => {
                        this.surveyFormService.updateForm(survey);
                        return SurveyActions.loadSurveyRequestSuccess({
                            survey,
                        });
                    }),
                    catchError((httpResponse) =>
                        of(
                            SurveyActions.loadSurveyRequestFailure({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    loadByToken$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.loadSurveyRequestByToken),
            concatLatestFrom(() =>
                this.store.select(UserSelectors.selectIsLoggedIn)
            ),
            exhaustMap(([{ token }, isLoggedIn]) => {
                return this.surveyAnonymousService
                    .surveyAnonTokenGet({ token })
                    .pipe(
                        map((survey) => {
                            this.surveyAnswersFormService.updateFormFromSurvey(
                                survey,
                                isLoggedIn
                            );
                            return SurveyActions.loadSurveyRequestSuccess({
                                survey,
                            });
                        }),
                        catchError((httpResponse) =>
                            of(
                                SurveyActions.loadSurveyRequestFailure({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    update$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.updateSurveyRequest),
            exhaustMap(({ updateOnlyCore, forceReloadQuestions }) => {
                const surveyViewModel = this.surveyFormService.getEntity();
                const questions = surveyViewModel.surveyViewModel.questions;
                if (updateOnlyCore) {
                    surveyViewModel.surveyViewModel.questions = null;
                }
                return this.surveyService.surveyPut(surveyViewModel).pipe(
                    map((survey) => {
                        const surveyClone = cloneDeep(survey);
                        if (updateOnlyCore && !forceReloadQuestions) {
                            surveyClone.questions = questions;
                        }
                        this.surveyFormService.updateForm(surveyClone);
                        return SurveyActions.updateSurveyRequestSuccess({
                            survey,
                            updateOnlyCore,
                        });
                    }),
                    catchError((httpResponse) =>
                        of(
                            SurveyActions.updateSurveyRequestFailure({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );

    alertOnUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.updateSurveyRequestSuccess),
            concatLatestFrom(() =>
                this.store.select(SurveySelectors.selectSurvey)
            ),
            mergeMap(() => {
                this.surveySettingsDialogService?.close();
                return [AlertActions.displaySuccess({ key: 'survey-update' })];
            })
        )
    );

    resetUnsavedChanges$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(SurveyActions.resetUnsavedChanges),
                concatLatestFrom(() =>
                    this.store.select(SurveySelectors.selectSurvey)
                ),
                tap(([, survey]) => {
                    this.surveyFormService.updateBaseForm(survey);
                })
            ),
        { dispatch: false }
    );

    sendAnswers$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.sendAnswersRequest),
            exhaustMap(() => {
                const surveyAnswersWrapperViewModel =
                    this.surveyAnswersFormService.getEntity();
                return this.surveyAnonymousService
                    .surveyAnonPost(surveyAnswersWrapperViewModel)
                    .pipe(
                        map((results) => {
                            return SurveyActions.sendAnswersRequestSuccess({
                                results,
                            });
                        }),
                        catchError((httpResponse) =>
                            of(
                                SurveyActions.sendAnswersRequestFailure({
                                    error:
                                        httpResponse?.error ??
                                        httpResponse.toString(),
                                })
                            )
                        )
                    );
            })
        )
    );

    errorOnSendAnswersFailed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SurveyActions.sendAnswersRequestFailure),
            map(({ error }) => {
                return AlertActions.displayError({ key: 'survey-' + error });
            })
        )
    );

    constructor(
        private actions$: Actions,
        private surveyService: SurveyService,
        private surveyAnonymousService: SurveyAnonymousService,
        private surveyFormService: SurveyFormService,
        private surveyAnswersFormService: SurveyAnswersFormService,
        private store: Store,
        private surveySettingsDialogService: CompanyAdminSurveySettingsDialogService
    ) {}
}
