import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BslkSize } from '@bslk/types/bslk-types';

@Component({
    selector: 'bslk-round-icon',
    standalone: true,
    templateUrl: './round-icon.component.html',
    imports: [MatIconModule, NgIf, MatTooltipModule, NgClass],
})
export class BslkRoundIconComponent {
    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

    @Input() icon: string;
    @Input() tooltip: string;
    @Input() color: string = 'green';
    @Input() size: BslkSize = 'medium';
    @Input() isButton = false;
    @Input() isDisabled = false;

    emitClick() {
        if (!this.isDisabled) {
            this.onClick.emit();
        }
    }
}
