import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IDialogService } from 'app/shared/services/dialog-service';
import {
    CompanyAdminSurveySettingsDialogComponent,
    CompanyAdminSurveySettingsInput,
} from './settings-dialog.component';

@Injectable({ providedIn: 'root' })
export class CompanyAdminSurveySettingsDialogService implements IDialogService {
    private dialogRef: MatDialogRef<CompanyAdminSurveySettingsDialogComponent>;

    constructor(private dialog: MatDialog) {}

    open(input: CompanyAdminSurveySettingsInput) {
        this.dialogRef = this.dialog.open(
            CompanyAdminSurveySettingsDialogComponent,
            { data: input, disableClose: true }
        );
    }

    close() {
        this.dialogRef?.close();
    }
}
