<survey-generic-details [question]="question" [index]="index">
    <bslk-chart
        #bslkChart
        class="grow"
        [series]="series"
        [options]="chartOptions"
    ></bslk-chart>

    <div class="flex items-center gap-2 text-lg" actions>
        <mat-icon
            class="text-current"
            [svgIcon]="'heroicons_outline:star'"
        ></mat-icon>
        {{ "survey.average-rating" | transloco }}:
        <span class="font-bold">{{ question.averageRating }}</span>
    </div>
</survey-generic-details>
