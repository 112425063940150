import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { BslkTagButtonComponent } from '@bslk/components/cards/tag-button/tag-button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { SurveyListActions } from 'app/store/survey/survey-list/survey-list.actions';
import { SurveyListSelectors } from 'app/store/survey/survey-list/survey-list.selectors';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CompanyAdminSurveySettingsDialogService } from './settings-dialog/settings-dialog.service';
import { SurveyCardComponent } from './survey-card/survey-card.component';

@Component({
    selector: 'company-admin-surveys',
    templateUrl: './surveys.component.html',
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        AsyncPipe,
        BslkTagButtonComponent,
        MatIconModule,
        NgFor,
        NgIf,
        SurveyCardComponent,
        TranslocoModule,
        MatPaginatorModule,
        NgxSkeletonLoaderModule,
        NgClass,
    ],
})
export class CompanyAdminSurveysComponent implements OnInit {
    surveys = [];

    isLoading$ = this.store.select(SurveyListSelectors.selectIsLoading);
    pageIndex$ = this.store.select(SurveyListSelectors.selectPageIndex);
    totalPages$ = this.store.select(SurveyListSelectors.selectTotalPages);
    totalCount$ = this.store.select(SurveyListSelectors.selectTotalCount);
    pageSize$ = this.store.select(SurveyListSelectors.selectPageSize);

    constructor(
        private store: Store,
        private surveySettingsDialogService: CompanyAdminSurveySettingsDialogService,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.store.dispatch(SurveyListActions.LoadListRequest());

        // To prevent annoying "Expression has changed after it was checked" error on creation
        this.store
            .select(SurveyListSelectors.selectData)
            .subscribe((surveys) => {
                this.surveys = surveys;
                this.cdRef.detectChanges();
            });
    }

    createSurvey() {
        this.surveySettingsDialogService.open({
            isEditing: false,
            isFromEntityPage: false,
        });
    }

    pageChangeEvent(event: PageEvent) {
        this.store.dispatch(
            SurveyListActions.FilterListRequest({
                filters: [{ key: 'pageIndex', value: event.pageIndex }],
            })
        );
    }
}
