import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetDirective } from '@ngrx/component';
import { Store } from '@ngrx/store';
import { ESurveyQuestionType } from 'app/api';
import { SurveySelectors } from 'app/store/survey/survey/survey.selectors';
import { SurveyMultipleChoiceDetailsComponent } from './answer-details/multiple-choice-details/multiple-choice-details.component';
import { SurveyOpenAnswerDetailsComponent } from './answer-details/open-answer-details/open-answer-details.component';
import { SurveyRatingDetailsComponent } from './answer-details/rating-details/rating-details.component';
import { SurveySingleChoiceDetailsComponent } from './answer-details/single-choice-details/single-choice-details.component';
import { SurveyParticipantsComponent } from './participants/participants.component';

@Component({
    selector: 'company-admin-survey-configuration-answers',
    templateUrl: './answers.component.html',
    standalone: true,
    imports: [
        TranslocoModule,
        NgFor,
        LetDirective,
        NgSwitch,
        NgIf,
        NgSwitchCase,
        SurveyOpenAnswerDetailsComponent,
        SurveyRatingDetailsComponent,
        SurveySingleChoiceDetailsComponent,
        SurveyMultipleChoiceDetailsComponent,
        SurveyParticipantsComponent,
    ],
})
export class CompanyAdminSurveyConfigurationAnswersComponent {
    ESurveyQuestionType = ESurveyQuestionType;

    survey$ = this.store.select(SurveySelectors.selectSurvey);

    constructor(private store: Store) {}
}
