<div class="flex flex-col gap-5">
    <div class="flex justify-end sticky top-5 z-10">
        <div
            class="flex p-4 border-primary border bg-white rounded-lg shadow-lg gap-4 items-center"
        >
            <bslk-classic-button
                (onClick)="addQuestion()"
                [icon]="'heroicons_outline:plus'"
                >{{ "survey.add-question" | transloco }}</bslk-classic-button
            >
            <bslk-classic-button
                (onClick)="save()"
                [background]="'primary'"
                [isDisabled]="isSaveDisabled"
                [disabledTooltip]="isInvalid ? 'survey.invalid-form' : null"
                [icon]="'heroicons_outline:check'"
                >{{ "shared.common.save" | transloco }}</bslk-classic-button
            >
            <bslk-tag
                *ngIf="isDirty"
                [background]="'warn'"
                [icon]="'heroicons_outline:exclamation-triangle'"
                >{{ "survey.unsaved-changes" | transloco }}</bslk-tag
            >
        </div>
    </div>

    <company-admin-survey-configuration-question
        *ngFor="let formGroup of formArray.controls; let i = index"
        [group]="formGroup"
        [index]="i"
    ></company-admin-survey-configuration-question>

    <div
        *ngIf="formArray.controls.length === 0"
        class="grow flex flex-col justify-center gap-5 text-lg items-center mt-10"
    >
        <mat-icon
            [svgIcon]="'heroicons_outline:question-mark-circle'"
            class="text-current icon-size-12"
        ></mat-icon>
        {{ "survey.no-question" | transloco }}
    </div>
</div>
