import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { ActionCreator, Store } from '@ngrx/store';
import { IFormService } from 'app/shared/services/form/form-service';
import {
    Observable,
    catchError,
    debounceTime,
    exhaustMap,
    map,
    of,
    switchMap,
    tap,
} from 'rxjs';
import { AlertActions } from '../../alert/alert.actions';
import { RessourceListActions } from './ressource-list-action-group-creator';
import { RessourceListSelectors } from './ressource-list-selectors.creator';
import { IResourceList } from './ressource-list-state';

export function createLoadListRequestEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    store: Store,
    actions$: Actions,
    apiService: any,
    ressourceListActions: RessourceListActions<T, TList>,
    ressourceListSelectors: RessourceListSelectors<T>,
    apiCall: (filters) => Observable<TList>
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(ressourceListActions.LoadListRequest),
            debounceTime(300),
            concatLatestFrom(() =>
                store.select(ressourceListSelectors.selectFilters)
            ),
            exhaustMap(([, filters]) => {
                const boundApiCall = apiCall.bind(apiService);

                const apiObservable = boundApiCall(
                    filters
                ) as Observable<TList>;
                return apiObservable.pipe(
                    map((response) =>
                        ressourceListActions.LoadListSuccess({ response })
                    ),
                    catchError((httpResponse) =>
                        of(
                            ressourceListActions.LoadListFailure({
                                error:
                                    httpResponse?.error ??
                                    httpResponse.toString(),
                            })
                        )
                    )
                );
            })
        )
    );
}

export function createLoadItemRequestEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    store: Store,
    actions$: Actions,
    apiService: any,
    ressourceListActions: RessourceListActions<T, TList>,
    ressourceListSelectors: RessourceListSelectors<T>,
    apiCall: (filters) => Observable<T>
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(ressourceListActions.LoadItemRequest),
            concatLatestFrom(() =>
                store.select(ressourceListSelectors.selectData)
            ),
            exhaustMap(([{ id }, data]) => {
                const item = data.find((d) => d?.id === id);
                if (item) {
                    return of(
                        ressourceListActions.LoadItemRequestSuccess({
                            response: item,
                        })
                    );
                }

                const boundApiCall = apiCall.bind(apiService);

                const apiObservable = boundApiCall({ id }) as Observable<T>;

                return apiObservable.pipe(
                    map((response) => {
                        return ressourceListActions.LoadItemRequestSuccess({
                            response,
                        });
                    }),
                    catchError(() =>
                        of(ressourceListActions.LoadItemRequestFailure)
                    )
                );
            })
        )
    );
}

export function createSelectedItemEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    store: Store,
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    ressourceListSelectors: RessourceListSelectors<T>,
    formService: IFormService<T>
) {
    return createEffect(
        () =>
            actions$.pipe(
                ofType(ressourceListActions.SelectItem),
                concatLatestFrom(() =>
                    store.select(ressourceListSelectors.selectSelectedItem)
                ),
                tap(([, selectedItem]) => {
                    formService.updateForm(selectedItem);
                })
            ),
        { dispatch: false }
    );
}

export function createUpdateSelectedItemEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    apiService: any,
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    formService: IFormService<T>,
    alertKey: string,
    apiCall: (entity) => Observable<any>
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(ressourceListActions.UpdateSelectedItem),
            exhaustMap(() => {
                const entity = formService.getEntity();
                const boundApiCall = apiCall.bind(apiService);

                const apiObservable = boundApiCall(entity) as Observable<any>;

                return apiObservable.pipe(
                    switchMap(() =>
                        of(
                            ressourceListActions.UpdateSelectedItemSuccess(),
                            AlertActions.displaySuccess({ key: alertKey })
                        )
                    ),
                    catchError(() =>
                        of(
                            ressourceListActions.UpdateSelectedItemFail(),
                            AlertActions.displayGenericError()
                        )
                    )
                );
            })
        )
    );
}

export function createDeleteItemEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    apiService: any,
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    alertKey: string,
    apiCall: (id) => Observable<number>
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(ressourceListActions.DeleteItem),
            exhaustMap(({ id }) => {
                const boundApiCall = apiCall.bind(apiService);

                const apiObservable = boundApiCall({ id }) as Observable<any>;

                return apiObservable.pipe(
                    switchMap(() =>
                        of(
                            ressourceListActions.DeleteItemSuccess(),
                            AlertActions.displaySuccess({ key: alertKey })
                        )
                    ),
                    catchError(() =>
                        of(
                            ressourceListActions.DeleteItemFail(),
                            AlertActions.displayGenericError()
                        )
                    )
                );
            })
        )
    );
}

export function createStartCreationEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    formService: IFormService<T>
) {
    return createEffect(
        () =>
            actions$.pipe(
                ofType(ressourceListActions.StartCreation),
                tap(() => {
                    formService.initForm();
                })
            ),
        { dispatch: false }
    );
}

export function createCreateItemEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    apiService: any,
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    formService: IFormService<T>,
    alertKey: string,
    apiCall: (entity) => Observable<any>
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(ressourceListActions.CreateItem),
            exhaustMap(() => {
                const entity = formService.getEntity();
                const boundApiCall = apiCall.bind(apiService);

                const apiObservable = boundApiCall(entity) as Observable<any>;

                return apiObservable.pipe(
                    switchMap(() => {
                        return of(
                            ressourceListActions.CreateItemSuccess(),
                            AlertActions.displaySuccess({ key: alertKey })
                        );
                    }),
                    catchError(() =>
                        of(
                            ressourceListActions.CreateItemFail(),
                            AlertActions.displayGenericError()
                        )
                    )
                );
            })
        )
    );
}

export function createReloadEffect<
    T extends { id?: number },
    TList extends IResourceList<T>
>(
    actions$: Actions,
    ressourceListActions: RessourceListActions<T, TList>,
    additionalActionCreators?: ActionCreator[]
) {
    return createEffect(() =>
        actions$.pipe(
            ofType(
                ressourceListActions.Initialize,
                ressourceListActions.InitializeWithFilters,
                ressourceListActions.HardResetWithFilters,
                ressourceListActions.FilterListRequest,
                ressourceListActions.UpdateSelectedItemSuccess,
                ressourceListActions.CreateItemSuccess,
                ressourceListActions.DeleteItemSuccess,
                ...(additionalActionCreators ? additionalActionCreators : [])
            ),
            map(ressourceListActions.LoadListRequest)
        )
    );
}
